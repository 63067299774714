import React from "react";

import { Slider } from "../components/";
import { PartnerData, GuideData } from "../types";

import main1 from "../assets/guide/1/main-1@2x.png";
import main2 from "../assets/guide/2/main-2@2x.png";
import main3 from "../assets/guide/3/main-3@2x.png";
import main4 from "../assets/guide/4/main-4@2x.png";

import content4_1 from "../assets/guide/4/content-4-img-1@2x.png";
import content4_2 from "../assets/guide/4/content-4-img-2@4x.gif";
import content4_3 from "../assets/guide/4/content-4-img-3@2x.png";
import content4_4 from "../assets/guide/4/content-4-img-4@2x.png";
import content1_1 from "../assets/guide/1/content-1-img-1@2x.png";
import content2_1 from "../assets/guide/2/content-2-img-1@2x.png";
import content2_2 from "../assets/guide/2/content-2-img-2@2x.png";
import content2_3 from "../assets/guide/2/content-2-img-3@2x.png";
import content3_1_1 from "../assets/guide/3/content-3-img-1-1@2x.png";
import content3_1_2 from "../assets/guide/3/content-3-img-1-2@2x.png";
import content3_1_3 from "../assets/guide/3/content-3-img-1-3@2x.png";
import content3_1_4 from "../assets/guide/3/content-3-img-1-4@2x.png";
import content3_2_1 from "../assets/guide/3/content-3-img-2-1@2x.png";
import content3_2_2 from "../assets/guide/3/content-3-img-2-2@2x.png";
import content3_3 from "../assets/guide/3/content-3-img-3@2x.png";

import shinhanLogo from "../assets/logo-sinhan@2x.png";
import ibkLogo from "../assets/logo-ibk@2x.png";
import miraeLogo from "../assets/logo-mirae@2x.png";
import samjanLogo from "../assets/logo-samjan@2x.png";
import kbLogo from "../assets/logo-kb@2x.png";
import kyoboLogo from "../assets/logo-kyobo@2x.png";
import saraminLogo from "../assets/logo-saramin@2x.png";
import yanoljaLogo from "../assets/logo-ya@2x.png";
import bithumbLogo from "../assets/logo-bit@2x.png";

export const guideData: GuideData[] = [
  {
    title: "나를 증명하는\n새로운 방식, 쯩",
    subTitle: "쯩을 소개합니다",
    img: main1,
    buttonText: "쯩 탄생 비하인드 스토리",
    buttonLink: "/4",
    keyColor: "#99AFFF",
    desc: (
      <>
        <p>
          <span>
            길고 어려운 공인인증서 비밀번호...
            <br />
            인증받았는데 또 받으라고 하는 <br />
            휴대폰 문자인증...
            <br />
            이곳저곳 흩어져 있는 다양한 증명서들...
          </span>
          <span>
            일상에서 누구나 겪는 불편한 경험들이죠.
            <br />
            ‘쯩’은 이러한 불편함을 해소하고 싶다는 바람에서 시작되었습니다.
            <br />
            내가 누구인지 쉽게 증명할 수 있는 방법, 또 내가 가진 증명서들을 한
            번에 관리할 수 있는 방법들을 고민한 끝에 마침내 쯩이 탄생하게
            되었습니다.
          </span>
        </p>

        <img src={content1_1} alt="나를 증명하는 새로운 방식 쯩" />

        <h2>
          쯩으로 쉽고 간편하게
          <br />
          나를 증명해보세요
        </h2>
        <p>
          <span>
            ‘쯩’은 내 개인 정보를 블록체인 기술로 암호화하여 휴대폰에 안전하게
            보관하고, 한 번의 생체 인증으로 간편하게 제출할 수 있는 새로운
            방식의 자기 증명 서비스입니다.
          </span>
          <span>
            앞으로 다양한 MyID와 증명서 서비스를 추가할 예정이니 기대해주세요!
          </span>
        </p>
      </>
    ),
  },
  {
    title: "내 증명서를 한 번에!",
    subTitle: "증명서에 대해 알아보아요",
    img: main2,
    buttonText: "증명서 이용 가이드",
    buttonLink: "/3",
    keyColor: "#C398FF",
    desc: (
      <>
        <h2>이제 쯩으로 한 번에 관리하세요</h2>
        <p>
          필요할 때 찾기 어렵고 매번 새로 발급받아야 하는 증명서, 관리하기 너무
          힘드셨죠?
          <br />
          이제 사용은 간편하고 관리는 안전한 쯩으로 증명서를 관리해보세요.
        </p>
        <div className="divider"></div>
        <h2>내 증명서를 한 곳에</h2>
        <p>이곳저곳 흩어져 있는 증명서, 이제 ‘쯩’에서 한 번에 관리하세요.</p>
        <img src={content2_1} alt="내 증명서를 한 번에!" />
        <h2>버튼 하나로 끝나는 증명서 제출</h2>
        <p>
          더 이상 일일이 찾아다닐 필요 없이 ‘쯩’에서 증명서를 바로 제출하세요.
        </p>
        <img src={content2_2} alt="내 증명서를 한 번에!" />
        <h2>블록체인에 보관되는 내 증명서</h2>
        <p>
          소중한 내 개인정보, 걱정하지 마세요. <br />
          해킹이 불가능한 블록체인으로 내 증명서 정보를 안전하게 보관합니다.
        </p>
        <img src={content2_3} alt="내 증명서를 한 번에!" />
      </>
    ),
  },
  {
    title: "증명서 이용 가이드",
    subTitle: "증명서에 대해 알아보아요",
    img: main3,
    buttonText: "내 증명서를 한 번에!",
    buttonLink: "/2",
    keyColor: "#F6C255",
    desc: (
      <>
        <p>
          지금부터 ‘쯩’에 증명서를 등록하여 보관하고 제출하는 방법에 대해 알려
          드릴게요.
        </p>
        <div className="divider"></div>
        <h2>증명서 등록하기</h2>
        <p>
          보유한 모든 증명서를 촬영해 등록하고 한 번에 관리하세요. 블록체인에
          기록되어 쯩에서 한 번에 관리할 수 있습니다.
        </p>
        <div className="slider">
          <Slider
            images={[content3_1_1, content3_1_2, content3_1_3, content3_1_4]}
            alt="증명서 이용 가이드"
          />
        </div>

        <h2>증명서 제출하기</h2>
        <p>
          증명서를 등록하고 QR 코드를 스캔해보세요.
          <br />
          내가 가진 증명서를 빠르고 안전하게, 제한 없이 제출할 수 있어요.
        </p>
        <div className="slider">
          <Slider
            images={[content3_2_1, content3_2_2]}
            alt="증명서 이용 가이드"
          />
        </div>

        <h2>사람인에 제출하기</h2>
        <p>
          채용 시 필요한 증명서를 사람인에 한 번에 제출할 수 있어요. (연동
          서비스 7월 오픈 예정)
        </p>
        <img className="banner" src={content3_3} alt="증명서 이용 가이드" />
      </>
    ),
  },
  {
    title: "쯩 탄생\n비하인드 스토리",
    subTitle: "쯩을 소개합니다",
    img: main4,
    buttonText: "나를 증명하는 새로운 방식, 쯩",
    buttonLink: "/1",
    keyColor: "#4773CD",
    desc: (
      <>
        <p>
          <span>
            여러분 반가워요!
            <br />
            저는 쯩 도우미 키오(KIO)입니다.
          </span>
        </p>
        <img src={content4_1} alt="쯩 탄생 비하인드 스토리" />
        <p>
          <span>
            쯩이 어떻게 탄생했는지 궁금하시죠?
            <br />
            이제부터 쯩 탄생 스토리에 대해 알려드릴게요.
          </span>
          <span>
            쯩은 ‘세상 모든 사람들이 자신의 정보를 누군가에게 맡겨 관리하는 것이
            아니라, 자기 스스로 자신의 정체성을 증명하고 보호할 수 있는 세상’을
            꿈꾸는 아이콘루프 사람들에 의해 만들어졌습니다.
          </span>
        </p>
        <h2>쯩 이름과 로고 탄생은?</h2>
        <p>
          <span>
            우리가 신분 확인을 위해서는 주민등록증, 운전면허증, 학생증 같은 각종
            신분증들을 비롯하여 졸업증명서, 재직증명서, 수료증 등… 그 외에도
            수많은 증명서들이 필요합니다. <br />
            우리는 나를 증명하는 하나의 도구이자 문자인 ‘증’들을 모아 ‘쯩’이라는
            이름을 만들었습니다.
          </span>
          <span>
            로고는 직관적으로 이해할 수 있도록 브랜드 이름인 ‘쯩’을 그대로
            시각화하여 디자인하였습니다.
          </span>
        </p>

        <img src={content4_2} alt="쯩 탄생 비하인드 스토리" />
        <p>
          동그라미 모티프는 True/Proof를 의미하며 거기에 ㅇ와 ㅡ가 만나 나를
          상징하는 Face를 의미하는 동시에 우주 행성이 갖는 상징으로 Future를
          의미하기도 합니다.
        </p>

        <img src={content4_3} alt="쯩 탄생 비하인드 스토리" />
        <p>
          나를 증명하는 새로운 방법인 ‘쯩’은 미래의 증명 서비스를 선도하겠다는
          목표와 함께 단순한 서비스 기능만이 아니라 ‘자신의 정보를 스스로
          보호하고 증명하는 MyID 세상’을 만들겠다는 철학과 의지가 담겨 있습니다.
        </p>

        <h2>당신을 도와주는 친구 ‘키오(KIO)’</h2>
        <p>저에 대해 소개할게요!</p>

        <img src={content4_4} alt="쯩 탄생 비하인드 스토리" />
        <p>
          안녕하세요 :) 저는 쯩의 도우미 ‘키오’입니다. 쯩을 옆으로 돌리면 영어로
          KIO가 되거든요! 여러분을 만나게 되어서 무척 반갑습니다. 여러분이 앱을
          사용하다가 모르는 게 있을 때 언제나 저 키오가 도와 드릴 거예요. <br />
          다음에 또 만나요~
        </p>
      </>
    ),
  },
];

export const partnerData: PartnerData[] = [
  {
    src: shinhanLogo,
    name: "신한은행",
  },
  {
    src: ibkLogo,
    name: "IBK기업은행",
  },
  {
    src: miraeLogo,
    name: "PAYCO",
  },
  {
    src: samjanLogo,
    name: "삼성전자",
  },
  {
    src: kbLogo,
    name: "KB증권",
  },
  {
    src: kyoboLogo,
    name: "교보",
  },
  {
    src: saraminLogo,
    name: "사람인",
  },
  {
    src: yanoljaLogo,
    name: "야놀자",
  },
  {
    src: bithumbLogo,
    name: "빗썸",
  },
];
