import React, { RefObject, useState, useCallback, useRef } from "react";
import { createUseStyles } from "react-jss";
import { ThemeType } from "../styles/theme";
import { Selector, Button } from "../components/";
import clsx from "clsx";
import { CATEGORY_LIST, EMAIL_DOMAIN_LIST } from "../constants/selector";
import API from "../apis/rest";
import on from "../assets/on2@2x.png";
import off from "../assets/off2@2x.png";
import downArrow from "../assets/downArrow@2x.png";
import { Helmet } from "react-helmet";
import useOutsideClick from "../hooks/useOutsideClick";
import {
  showKeyboard,
  dismissKeyboard,
  closeWebView,
} from "../apis/javascriptChannel";
import { getDeviceClassName } from "../utils";

const useStyles = createUseStyles((theme: ThemeType) => ({
  container: {
    width: "100%",
    margin: "0 auto",
    padding: `30px ${theme.gap}px 0px`,
    "&.focused": {
      paddingBottom: 240,
    },
    "&.focused.pc": {
      paddingBottom: 0,
    },
  },
  h1: {
    fontSize: "2.0rem",
    marginBottom: 10,
    fontWeight: theme.demiLight,
    lineHeight: "3.0rem",
  },
  h2: {
    fontSize: "1.8rem",
    marginBottom: 12,
    fontWeight: theme.demiLight,
  },
  divider: {
    height: 1,
    borderTop: `1px solid ${theme.monoDivider}`,
    marginBottom: 26,
  },
  p: {
    fontSize: "1.6rem",
    color: theme.mono50,
    marginBottom: 26,
    lineHeight: "2.6rem",
  },
  checkbox: {
    height: "6.8rem",
    lineHeight: "6.8rem",
    marginBottom: "0.6rem",
    color: theme.mono0,
    "& input": {
      display: "none",
      "&:checked+label": {
        background: `url(${on}) center left no-repeat`,
        backgroundSize: "22px 22px",
      },
    },
    "& label": {
      display: "inline-block",
      background: `url(${off}) center left no-repeat`,
      backgroundSize: "22px 22px",
      padding: "0px 30px 0px",
      fontSize: "1.6rem",
      height: "6.4rem",
      lineHeight: "6.4rem",
      marginTop: -1,
    },
  },
  select: {
    width: "100%",
    fontSize: "1.8rem",
    color: theme.mono0,
    border: `1px solid ${theme.monoDivider}`,
    borderRadius: 6,
    height: 60,
    padding: "15px 14px",
    background: `url(${downArrow}) no-repeat`,
    backgroundSize: "20px 20px",
    backgroundPosition: "right 16px center",
    marginBottom: 14,
  },
  input: {
    width: "calc(100% - 30px)",
    fontSize: "1.8rem",
    color: theme.mono0,
    border: `1px solid ${theme.monoDivider}`,
    borderRadius: 6,
    height: 60,
    padding: "0px 14px",
    marginBottom: 30,
    "-webkit-appearance": "none",
    "&.error": {
      marginBottom: 3,
    },
  },
  error: {
    fontSize: "1.4rem",
    color: theme.danger,
    marginBottom: 10,
  },
  textarea: {
    width: "100%",
    position: "relative",
    marginBottom: 40,
    height: 230,
  },
  textarea__content: {
    width: "100%",
    height: 124,
    paddingTop: 16,
    paddingBottom: 40,
    fontSize: "1.8rem",
    lineHeight: "2.4rem",
    resize: "none",
  },
  textarea__checker: {
    position: "absolute",
    bottom: 16,
    right: 14,
    fontSize: "1.4rem",
    color: theme.mono50,
  },
  cnt: {
    color: theme.accent,
    "&.error": {
      color: theme.danger,
    },
  },
}));

const validateEmail = (email: string) =>
  email.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);

const ContactPage: React.FC = () => {
  const classes = useStyles();

  // Form State
  const [isAgreed, setIsAgreed] = useState<boolean>(false);
  const [selectedCategory, setCategory] = useState<number>(-1);
  const [email, setEmail] = useState<string>("");
  const [content, setContent] = useState<string>("");

  // UI State
  const emailRef = useRef<HTMLInputElement>();
  const textareaRef = useRef<HTMLTextAreaElement>();

  const [emailError, setEmailError] = useState<boolean>(false);
  const [isCategorySelectorShown, showCategorySelector] = useState<boolean>(
    false
  );
  const toggleCategorySelector = useCallback(() => {
    //@ts-ignore
    dismissKeyboard();
    showCategorySelector(!isCategorySelectorShown);
  }, [isCategorySelectorShown]);

  const [isEmailSelectorShown, showEmailSelector] = useState<boolean>(false);
  const toggleEmailSelector = useCallback(() => {
    showEmailSelector(!isEmailSelectorShown);
  }, [isEmailSelectorShown]);

  const [isEmailFocused, setEmailFocused] = useState<boolean>(false);
  const [isTextareaFocused, setTextareaFocused] = useState<boolean>(false);

  const handleEmailKeyPress = useCallback(
    (e) => {
      const _value = e.target.value;
      const key = e.key === "Unidentified" ? _value[_value.length - 1] : e.key;
      if (
        (key === "@" || key === "Backspace") &&
        !_value.substr(0, _value.length - 1).includes("@") &&
        _value[_value.length - 1] === "@"
      ) {
        //@ts-ignore
        dismissKeyboard();
        (emailRef.current as HTMLInputElement).blur();
        setTimeout(() => {
          toggleEmailSelector();
        }, 300);
      }
    },
    [toggleEmailSelector]
  );

  const handleEmailChange = useCallback((e) => {
    setEmail(e.target.value);
  }, []);

  const handleSetEmailDomain = useCallback(
    (idx: number) => {
      setEmail(`${email}${idx !== 0 ? EMAIL_DOMAIN_LIST[idx] : ""}`);
      if (idx === 0) {
        setTimeout(() => {
          (emailRef.current as HTMLInputElement).focus();
          //@ts-ignore
          showKeyboard();
        }, 300);
      }
    },
    [email]
  );

  const handleEmailFocus = useCallback(async () => {
    setEmailFocused(true);
    setTimeout(() => {
      window.scrollTo(0, 380);
    }, 0);
  }, []);

  const handleTextareaFocus = useCallback(async () => {
    if (!isTextareaFocused) {
      (textareaRef.current as HTMLTextAreaElement).focus();
      setTextareaFocused(true);
      setTimeout(() => {
        window.scrollTo(0, 460);
      }, 0);
    }
  }, [isTextareaFocused]);

  const handleTextareaBlur = useCallback(() => {
    if (isTextareaFocused) {
      dismissKeyboard();
      setTextareaFocused(false);
      if (/iPhone|iPad/i.test(navigator.userAgent)) {
        window.scrollTo(0, document.body.scrollHeight);
      }
    }
  }, [isTextareaFocused]);

  const handleSubmit = useCallback(async () => {
    const _emailTrimmed = email.trim();
    if (!validateEmail(_emailTrimmed)) {
      setEmailError(true);
      return;
    }

    try {
      setEmailError(false);
      const { status } = await API.fetchData<{
        emailTitle: string;
        fromAddress: string;
        content: string;
      }>("email", {
        emailTitle: CATEGORY_LIST[selectedCategory],
        fromAddress: _emailTrimmed,
        content,
      });

      console.log(status);

      if (status) {
        if (getDeviceClassName() === "pc") {
          alert("문의가 등록되었습니다.");
        } else {
          closeWebView();
        }
      } else {
        throw new Error();
      }
    } catch (e) {
      console.error(e);
    }
  }, [content, email, selectedCategory]);

  const isSubmitDisabled: boolean = !(
    isAgreed &&
    selectedCategory > -1 &&
    content
  );

  useOutsideClick(emailRef, () => {
    if (isEmailFocused) {
      dismissKeyboard();
      setEmailFocused(false);
    }
  });

  useOutsideClick(textareaRef, () => {
    if (isTextareaFocused) {
      handleTextareaBlur();
    }
  });

  return (
    <>
      <Helmet>
        <title>쯩 - 1:1 문의</title>
      </Helmet>
      <div>
        <div
          className={clsx(
            classes.container,
            (isTextareaFocused || isEmailFocused) && "focused",
            getDeviceClassName()
          )}
        >
          <h1 className={classes.h1}>
            안녕하세요!
            <br />쯩 서비스에 궁금하신 점을
            <br />
            이메일로 문의하세요
          </h1>
          <p className={classes.p}>
            아래의 양식에 맞추어 궁금하신 점을 문의해주세요. 문의하신 내용은
            작성해주신 이메일로 답변드리겠습니다. (토, 일 및 공휴일에는 신속하게
            답변드리지 못할 수 있는 점 양해 부탁드립니다)
          </p>
          <div className={classes.divider} />
          <h2 className={classes.h2}>개인 정보 수집 동의</h2>
          <p className={classes.p} style={{ marginBottom: 12 }}>
            - 수집 정보 : 이메일 주소
          </p>
          <p className={classes.p} style={{ marginBottom: 0 }}>
            입력하신 이메일 정보는 문의 접수, 연락, 기록 유지 등을 위해 수집 후
            5년간 보관합니다.
          </p>
          <div
            onClick={() => setIsAgreed(!isAgreed)}
            className={classes.checkbox}
          >
            <input
              type="checkbox"
              name="agreement"
              checked={isAgreed}
              onChange={() => setIsAgreed(!isAgreed)}
            />
            <label>동의합니다.</label>
          </div>
          <div className={classes.divider} style={{ marginBottom: 36 }} />
          <div onClick={toggleCategorySelector} className={classes.select}>
            {CATEGORY_LIST[selectedCategory] || "문의 종류를 선택하세요"}
          </div>
          <input
            className={clsx(classes.input, emailError && "error")}
            type="text"
            value={email}
            onKeyUp={handleEmailKeyPress}
            onChange={handleEmailChange}
            placeholder="이메일을 입력하세요"
            autoComplete="false"
            onFocus={() => handleEmailFocus()}
            ref={emailRef as RefObject<HTMLInputElement>}
          />
          {emailError && (
            <p className={classes.error}>
              이메일 주소 양식에 맞춰 입력해주세요
            </p>
          )}
          <div className={clsx(classes.input, classes.textarea)}>
            <textarea
              className={classes.textarea__content}
              name="content"
              value={content}
              onChange={(e) =>
                setContent(
                  e.target.value.length > 1000
                    ? e.target.value.substring(0, 1000)
                    : e.target.value
                )
              }
              onClick={() => handleTextareaFocus()}
              onBlur={handleTextareaBlur}
              ref={textareaRef as RefObject<HTMLTextAreaElement>}
              placeholder="문의하실 내용을 입력해주세요"
              autoComplete="false"
            />
            <p className={classes.textarea__checker}>
              <span
                className={clsx(classes.cnt, content.length >= 1000 && "error")}
              >
                {content.length}
              </span>
              자 입력/ 최대 1000자
            </p>
          </div>
        </div>
        <Button
          disabled={isSubmitDisabled}
          onClick={handleSubmit}
          text="등록"
        />
      </div>
      {isCategorySelectorShown && (
        <Selector
          title="문의 종류"
          toggleSelector={toggleCategorySelector}
          data={CATEGORY_LIST}
          selectedIdx={selectedCategory}
          select={setCategory}
        />
      )}
      {isEmailSelectorShown && (
        <Selector
          title="이메일"
          toggleSelector={toggleEmailSelector}
          data={EMAIL_DOMAIN_LIST}
          selectedIdx={-1}
          select={handleSetEmailDomain}
        />
      )}
    </>
  );
};

export default ContactPage;
