import React from "react";
import { createUseStyles } from "react-jss";
import { ThemeType } from "../../styles/theme";
import { Data } from "../../types";

interface Props {
  item: Data;
}

const useStyles = createUseStyles((theme: ThemeType) => ({
  title: {
    width: "83.3333333%",
    paddingTop: 2,
    fontSize: "1.8rem",
    display: "flex",
    "& span": {
      marginRight: 6,
      "&:first-of-type": {
        color: theme.accent,
      },
    },
  },
  createdAt: {
    fontSize: "1.4rem",
    color: theme.mono50,
  },
}));

const FaqItem: React.FC<Props> = (props) => {
  const classes = useStyles(props);
  const { item } = props;
  return (
    <>
      <div className={classes.title}>
        <span>Q.</span>
        <span>{item.title}</span>
      </div>
    </>
  );
};

export default FaqItem;
