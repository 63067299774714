import React from "react";
import ReactDOM from "react-dom";
import { HashRouter as Router } from "react-router-dom";
import "./styles/reset.css";
import "./styles/font.css";
import { ThemeProvider } from "react-jss";
import Theme from "./styles/theme";
import Routes from "./Routes";

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={Theme}>
      <Router>
        <Routes />
      </Router>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
