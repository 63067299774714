import React, { useCallback } from "react";
import { createUseStyles } from "react-jss";
import { ThemeType } from "../../styles/theme";
import closeWhite from "../../assets/closeWhite@2x.png";
import closeBlack from "../../assets/closeBlack@2x.png";
import { closeWebView } from "../../apis/javascriptChannel";

interface Props {
  title: string;
  isShown: boolean;
}

const useStyles = createUseStyles((theme: ThemeType) => ({
  header: {
    display: "block",
    width: "100%",
    padding: "15px 0px 17px",
    margin: "0 auto",
    textAlign: "center",
    fontSize: "1.8rem",
    fontWeight: theme.medium,
    position: "fixed",
    zIndex: 99999,
    lineHeight: "2.8rem",
    background: ({ isShown }: Props) => (isShown ? "#fff" : "transparent"),
    borderBottom: ({ isShown }: Props) =>
      isShown ? `1px solid ${theme.monoDivider}` : "none",
    "-webkit-transition": "background-color 0.2s, -webkit-transform 0.2s",
    transition: "background-color 0.2s, -webkit-transform 0.2s",
  },
  close: {
    background: ({ isShown }: Props) =>
      isShown
        ? `url(${closeBlack}) center center no-repeat`
        : `url(${closeWhite}) center center no-repeat`,
    backgroundSize: "22px 22px!important",
    width: "22px",
    height: "22px",
    display: "inline-block",
    position: "absolute",
    right: "23px",
    top: "19px",
  },
}));

const Header: React.FC<Props> = (props) => {
  const classes = useStyles(props);

  const handleClose = useCallback(() => {
    closeWebView();
  }, []);

  return (
    <header className={classes.header}>
      {props.isShown && <p>{props.title}</p>}
      <span onClick={handleClose} className={classes.close}></span>
    </header>
  );
};

export default Header;
