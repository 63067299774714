import { OpensourceData } from "../types";

export const opensourceData: OpensourceData[] = [
  {
    name: "shared_preferences",
    license: "BSD 3-Clause",
    type: "Flutter",
    link: "https://pub.dev/packages/shared_preferences",
  },
  {
    name: "splashscreen",
    license: "none",
    type: "Flutter",
    link: "https://pub.dev/packages/splashscreen",
  },
  {
    name: "permission_handler",
    license: "MIT",
    type: "Flutter",
    link: "https://pub.dev/packages/permission_handler",
  },
  {
    name: "webview_flutter",
    license: "BSD 3-Clause",
    type: "Flutter",
    link: "https://pub.dev/packages/webview_flutter",
  },
  {
    name: "local_auth",
    license: "BSD 3-Clause",
    type: "Flutter",
    link: "https://pub.dev/packages/local_auth",
  },
  {
    name: "sqflite",
    license: "MIT",
    type: "Flutter",
    link: "https://pub.dev/packages/sqflite",
  },
  {
    name: "http",
    license: "BSD 3-Clause",
    type: "Flutter",
    link: "https://pub.dev/packages/http",
  },
  {
    name: "pointycastle",
    license: "Bouncy Castle Licence",
    type: "Flutter",
    link: "https://pub.dev/packages/pointycastle",
  },
  {
    name: "json_serializable",
    license: "BSD 3-Clause",
    type: "Flutter",
    link: "https://pub.dev/packages/json_serializable",
  },
  {
    name: "path",
    license: "BSD 3-Clause",
    type: "Flutter",
    link: "https://pub.dev/packages/path",
  },
  {
    name: "qr_code_scanner",
    license: "BSD 2-Clause",
    type: "Flutter",
    link: "https://pub.dev/packages/qr_code_scanner",
  },
  {
    name: "intl",
    license: "BSD 3-Clause",
    type: "Flutter",
    link: "https://pub.dev/packages/intl",
  },
  {
    name: "uni_links",
    license: "BSD 2-Clause",
    type: "Flutter",
    link: "https://pub.dev/packages/uni_links",
  },
  {
    name: "url_launcher",
    license: "BSD 3-Clause",
    type: "Flutter",
    link: "https://pub.dev/packages/url_launcher",
  },
  {
    name: "async",
    license: "BSD 3-Clause",
    type: "Flutter",
    link: "https://pub.dev/packages/async",
  },
  {
    name: "provider",
    license: "MIT",
    type: "Flutter",
    link: "https://pub.dev/packages/provider",
  },
  {
    name: "logger",
    license: "MIT",
    type: "Flutter",
    link: "https://pub.dev/packages/logger",
  },
  {
    name: "cryptography",
    license: "Apache 2.0",
    type: "Flutter",
    link: "https://pub.dev/packages/cryptography",
  },
  {
    name: "steel_crypt",
    license: "MPL 2.0",
    type: "Flutter",
    link: "https://pub.dev/packages/steel_crypt",
  },
  {
    name: "firebase_ml_vision",
    license: "BSD 3-Clause",
    type: "Flutter",
    link: "https://pub.dev/packages/firebase_ml_vision",
  },
  {
    name: "camera",
    license: "BSD 3-Clause",
    type: "Flutter",
    link: "https://pub.dev/packages/camera",
  },
  {
    name: "path_provider",
    license: "BSD 3-Clause",
    type: "Flutter",
    link: "https://pub.dev/packages/path_provider",
  },
  {
    name: "cupertino_icons",
    license: "MIT",
    type: "Flutter",
    link: "https://pub.dev/packages/cupertino_icons",
  },
  {
    name: "flutter_widgets",
    license: "BSD 3-Clause",
    type: "Flutter",
    link: "https://pub.dev/packages/cupertino_icons",
  },
  {
    name: "package_info",
    license: "BSD 3-Clause",
    type: "Flutter",
    link: "https://pub.dev/packages/package_info",
  },
  {
    name: "iamport_flutter",
    license: "MIT",
    type: "Flutter",
    link: "https://github.com/iamport/iamport_flutter",
  },
  {
    name: "simple_animations",
    license: "MIT",
    type: "Flutter",
    link: "https://pub.dev/packages/simple_animations",
  },
  {
    name: "image_cropper",
    license: "BSD",
    type: "Flutter",
    link: "https://pub.dev/packages/image_cropper",
  },
  {
    name: "photo_view",
    license: "MIT",
    type: "Flutter",
    link: "https://pub.dev/packages/photo_view",
  },
  {
    name: "store_direct",
    license: "MIT",
    type: "Flutter",
    link: "https://pub.dev/packages/store_redirect",
  },
  {
    name: "bcprov-jdk15on",
    license: "Bouncy Castle Licence",
    type: "Android",
    link: "https://mvnrepository.com/artifact/org.bouncycastle/bcprov-jdk15on",
  },
  {
    name: "materialdatetimepicker",
    license: "Apache 2.0",
    type: "Android",
    link: "https://github.com/wdullaer/MaterialDateTimePicker",
  },
  {
    name: "jmrtd",
    license: "GNU Lesser GPL",
    type: "Android",
    link: "https://github.com/E3V3A/JMRTD",
  },
  {
    name: "scuba-sc-android",
    license: "GNU Lesser GPL",
    type: "Android",
    link:
      "https://mvnrepository.com/artifact/net.sf.scuba/scuba-sc-android/0.0.10",
  },
  {
    name: "prov",
    license: "Bouncy Castle Licence",
    type: "Android",
    link:
      "https://mvnrepository.com/artifact/com.madgag.spongycastle/prov/1.50.0.0",
  },
  {
    name: "jj2000",
    license: "JJ2000 license",
    type: "Android",
    link: "https://mvnrepository.com/artifact/edu.ucar/jj2000/5.3",
  },
  {
    name: "jnbis",
    license: "Apache 2.0",
    type: "Android",
    link: "https://github.com/mhshams/jnbis",
  },
  // {
  //   name: "NFCPassportReader",
  //   license: "MIT",
  //   type: "iOS",
  //   link: "https://github.com/AndyQ/NFCPassportReader",
  // },
  {
    name: "CryptoSwift",
    license: "AS-IS",
    type: "iOS",
    link: "https://github.com/krzyzanowskim/CryptoSwift",
  },
  {
    name: "secp256k1",
    license: "MIT",
    type: "iOS",
    link: "https://github.com/greymass/secp256k1",
  },
];

export default opensourceData;
