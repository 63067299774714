import React from "react";
// @ts-ignore
import { Helmet } from "react-helmet";
import { createUseStyles } from "react-jss";
import { partnerData, guideData } from "../constants/GuideData";
import { FaqItem, List, FlatButton, SliderWithCaption } from "../components/";
import { ThemeType } from "../styles/theme";
import { PartnerData } from "../types";
import faqData from "../constants/FaqData";
import { launch } from "../apis/javascriptChannel";

const useStyles = createUseStyles((theme: ThemeType) => ({
  wrap: {
    width: "100%",
    padding: "50px 0px 20px",
    margin: "0 auto",
    "& > h1": {
      fontWeight: theme.medium,
      fontSize: "2.6rem",
      padding: `0px ${theme.gap}px 0px`,
    },
  },
  section: {
    padding: "60px 0px 0px",
    borderBottom: `14px solid ${theme.monoBg}`,
    "& > h2": {
      fontSize: "2.2rem",
      fontWeight: theme.demiLight,
      textAlign: "center",
      marginBottom: 26,
    },
    "&:first-of-type": {
      padding: "30px 0px 38px",
    },
    "&:last-of-type": {
      borderBottom: "none",
    },
  },
  logos: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    padding: `0px ${theme.gap}px!important`,
    marginBottom: "27px!important",
  },
  logo: {
    width: "33%",
  },
  img: {
    margin: "0 auto",
  },
}));

const Guide: React.FC = () => {
  const classes = useStyles();

  return (
    <>
      <Helmet>
        <title>쯩 - 가이드</title>
      </Helmet>
      <div id="main" className={classes.wrap}>
        <h1>가이드</h1>
        <div className={classes.section}>
          <div className="slider">
            <SliderWithCaption data={guideData} />
          </div>
        </div>
        <div className={classes.section}>
          <h2>자주 묻는 질문</h2>
          <List data={faqData} itemComponent={FaqItem} isGuidePage={true} />
        </div>
        <div className={classes.section}>
          <h2>파트너사</h2>
          <ul className={classes.logos}>
            {partnerData.map((item: PartnerData, i: number) => {
              return (
                <li key={i} className={classes.logo}>
                  <img className={classes.img} src={item.src} alt={item.name} />
                </li>
              );
            })}
          </ul>
          <FlatButton
            text="더 알아보기"
            onClick={() => {
              launch();
            }}
          />
        </div>
      </div>
    </>
  );
};

export default Guide;
