import React, { Fragment } from "react";
import { createUseStyles } from "react-jss";
import { ThemeType } from "../../styles/theme";

interface Props {
  item: {
    subTitle: string;
    title: string;
  };
  isGuideDetailPage: boolean;
}

const useStyles = createUseStyles((theme: ThemeType) => ({
  subTitle: {
    padding: `60px ${theme.gap}px 0`,
    position: "relative",
    fontSize: "1.4rem",
    lineHeight: "2.2rem",
    color: theme.mono100,
  },
  title: {
    padding: `4px ${theme.gap}px 0`,
    position: "relative",
    fontSize: ({ isGuideDetailPage }: Props) =>
      isGuideDetailPage ? "2.7rem" : "2.6rem",
    fontWeight: theme.medium,
    lineHeight: ({ isGuideDetailPage }: Props) =>
      isGuideDetailPage ? "3.7rem" : "3.6rem",
    color: theme.mono100,
  },
}));

const Caption: React.FC<Props> = (props) => {
  const classes = useStyles(props);
  const { item } = props;

  return (
    <>
      <p className={classes.subTitle}>{item.subTitle}</p>
      <h1 className={classes.title}>
        {item.title.split("\n").map((item: string, key: number) => {
          return (
            <Fragment key={key}>
              {item}
              <br />
            </Fragment>
          );
        })}
      </h1>
    </>
  );
};

export default Caption;
