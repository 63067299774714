import React from "react";
// @ts-ignore
import { Helmet } from "react-helmet";
import { createUseStyles } from "react-jss";
import { ThemeType } from "../styles/theme";
import { NoticeItem, List } from "../components/";
import noticeData from "../constants/NoticeData";

const useStyles = createUseStyles((theme: ThemeType) => ({
  wrap: {
    width: "100%",
  },
}));

const NoticePage: React.FC = () => {
  const classes = useStyles();

  return (
    <>
      <Helmet>
        <title>쯩 - 공지사항</title>
      </Helmet>
      <div className={classes.wrap}>
        <List data={noticeData} itemComponent={NoticeItem} />
      </div>
    </>
  );
};

export default NoticePage;
