import React, { useEffect } from "react";
import qs from "qs";
import { useParams, useLocation } from "react-router-dom";
// @ts-ignore
import { Helmet } from "react-helmet";
import { createUseStyles } from "react-jss";
import { ThemeType } from "../styles/theme";
import tosData from "../constants/TosData";

interface ParamTypes {
  tosId: string;
}

const useStyles = createUseStyles((theme: ThemeType) => ({
  wrap: {
    width: "100%",
    padding: `30px ${theme.gap}px`,
  },
  desc: {
    "& h1": {
      fontSize: "2.2rem",
      fontWeight: theme.demiLight,
      marginTop: 60,
      marginBottom: 20,
      "&:first-of-type": {
        marginTop: 0,
        marginBottom: 0,
      },
    },
    "& h2": {
      fontSize: "1.8rem",
      paddingTop: 30,
      fontWeight: theme.demiLight,
      "&:first-of-type": {
        paddingTop: 26,
      },
    },
    "& p": {
      color: theme.mono50,
      paddingTop: 6,
      "&.version": {
        paddingTop: 80,
      },
    },
    "& > ol:not(.sub)": {
      margin: 0,
      listStyle: "none",
      paddingLeft: 0,
      paddingTop: 6,
    },
    "& > ol:not(.sub) > li": {
      color: theme.mono50,
      marginTop: 8,
      "&:first-of-type": {
        marginTop: 0,
      },
      "& > ol.sub": {
        marginBottom: "16px",
      },
      "&:last-of-type > ol.sub": {
        marginBottom: "0px",
      },
    },
    "& ol.sub": {
      margin: 0,
      listStyle: "decimal",
      marginTop: 16,
      marginLeft: 14,
      paddingLeft: 14,
    },
    "& ol.sub > li": {
      color: theme.mono50,
      marginTop: 8,
      "&:first-of-type": {
        marginTop: 0,
      },
    },
    "& table": {
      width: "100%",
      color: theme.mono50,
      marginTop: 16,
      marginBottom: 12,
      fontSize: "1.4rem",
      lineHeight: "2.2rem",
      "& tr": {
        "& td": {
          padding: 4,
        },
        "&:first-of-type": {
          borderBottom: `1px solid ${theme.monoDivider}`,
          "& td": {
            borderBottom: `1px solid ${theme.monoDivider}`,
          },
        },
      },
    },
  },
}));

function hashLinkScroll(location: any) {
  const { search } = location;
  const { to } = qs.parse(search, { ignoreQueryPrefix: true });
  if (!!to) {
    setTimeout(() => {
      const element = document.getElementById(to as string);
      if (element) element.scrollIntoView();
    }, 0);
  }
}

const TosDetailPage: React.FC = () => {
  const classes = useStyles();
  const location = useLocation();
  const { tosId } = useParams<ParamTypes>();
  const item = tosData[Number(tosId)];

  useEffect(() => {
    hashLinkScroll(location);
  }, [location]);

  return (
    <div className={classes.wrap}>
      <Helmet>
        <title>쯩 - {item.title}</title>
      </Helmet>
      <div
        className={classes.desc}
        dangerouslySetInnerHTML={{
          __html: item.desc,
        }}
      ></div>
    </div>
  );
};

export default TosDetailPage;
