import React from "react";
// @ts-ignore
import { Helmet } from "react-helmet";
import { createUseStyles } from "react-jss";
import opensourceData from "../constants/OpensourceData";
import { ThemeType } from "../styles/theme";
import { OpensourceData } from "../types";

const useStyles = createUseStyles((theme: ThemeType) => ({
  wrap: {
    width: "100%",
    padding: "50px 0",
  },
  list: {},
  item: {
    padding: `0px ${theme.gap}px`,
    marginBottom: theme.gap,
    "& > span:first-of-type": {
      fontWeight: theme.medium,
    },
    "& > span:not(:first-of-type)": {
      color: theme.mono50,
    },
  },
}));

const OssPage: React.FC = () => {
  const classes = useStyles();

  return (
    <>
      <Helmet>
        <title>쯩 - 오픈소스 라이선스</title>
      </Helmet>
      <div className={classes.wrap}>
        <ul className={classes.list}>
          {opensourceData.map((item: OpensourceData, i: number) => {
            return (
              <li key={i} className={classes.item}>
                <span>{item.name}</span>
                <br />
                <span>{item.license}</span>
                <br />
                <span>
                  <a href={item.link}>{item.link}</a>
                </span>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default OssPage;
