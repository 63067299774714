import React from "react";
import { createUseStyles } from "react-jss";
import { ThemeType } from "../../styles/theme";
import { NoticeData } from "../../types";

interface Props {
  item: NoticeData;
}

const useStyles = createUseStyles((theme: ThemeType) => ({
  title: {
    width: "83.3333333%",
    fontSize: "1.8rem",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: 300,
  },
  createdAt: {
    fontSize: "1.4rem",
    color: theme.mono50,
  },
}));

const NoticeItem: React.FC<Props> = (props) => {
  const classes = useStyles(props);
  const { item } = props;
  return (
    <>
      <div className={classes.title}>{item.title}</div>
      <div className={classes.createdAt}>{item.createdAt}</div>
    </>
  );
};

export default NoticeItem;
