import "whatwg-fetch";

const ROOT_URL = `${process.env.REACT_APP_WAS_URL}`;

const urlWithRoot = (route: string, id?: string): string => {
  const _route = id ? `${route}/${id}` : route;
  return `${ROOT_URL}/v1/${_route}`;
};

const optionsWithParams = <T>({
  params,
  method,
}: {
  params: T;
  method?: string;
}) => {
  const _method = method || "POST";
  const headers: HeadersInit = {
    Accept: "*/*",
    "Content-Type": "application/json",
  };

  if (_method === "POST") {
    return {
      method: _method,
      headers,
      body: JSON.stringify(params),
    };
  }

  return {
    method: _method,
    headers,
  };
};

const handleErrors = (res: any) => {
  if (res.error) {
    throw Error(res.error);
  }
  return res;
};

const fetchData = <T>(url: string, params: T, method?: string) => {
  return window
    .fetch(
      urlWithRoot(url),
      optionsWithParams<T>({
        params,
        method,
      })
    )
    .then((res: Response) => res.json())
    .then(handleErrors)
    .catch((e) => {
      throw e;
    });
};

export default { fetchData };
