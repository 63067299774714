import { Data } from "../types";

export const tosData: Data[] = [
  {
    title: "쯩 서비스 이용약관",
    desc: `
      <h1>쯩 서비스 이용약관</h1>
      <h2>제1조 (목적)</h2>
      <p>본 약관은 주식회사 아이콘루프(이하 ‘회사’라 한다)가 제공하는 "쯩" 서비스 이용과 관련하여 회사, 발급기관, 이용기관, 사용자 간의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.</p>
      <h2>제2조 (용어의 정의)</h2>
      <p>본 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p>
      <ol class="sub">
        <li>쯩: 개인의 신원, 자격, 경력 등 개인의 속성과 관련된 정보(이하 '신원 등'이라 한다)에 대한 인증을 거쳐 발급된 크레덴셜 등을 사용자의 모바일 기기에 안전하게 저장, 관리 및 제출할 수 있는 기능을 제공하는 블록체인 기반의 신원증명 모바일 애플리케이션을 말합니다.</li>
        <li>쯩 서비스: 쯩 애플리케이션에서 제공되는 모든 서비스에 대한 총칭입니다.</li>
        <li>DID(Decentralized ID): 쯩 서비스의 각 주체 (발급기관, 이용기관, 사용자) 가 블록체인 상에서 생성한 고유한 식별값입니다.</li>
        <li>개인 키: DID 소유자가 전자서명을 하기 위해 사용하는 키. 개인 키와 쌍을 이루는 공개 키는 블록체인에 공개되어 누구나 메세지의 위변조를 검증할 수 있습니다.</li>
        <li>크레덴셜(Credential): 사용자의 신원 등에 대한 데이터가 발급기관의 인증 또는 사용자의 자기증명을 거친 후 개인키로 서명되어 발급된 DID 기반의 인증서.</li>
        <li>발급기관: 사용자의 요청에 따라 신원 등을 인증하고 개인키로 서명하여 크레덴셜을 발급하는 주체</li>
        <li>이용기관: 서비스 제공 등의 목적으로 이용자로부터 크레덴셜을 제출받아 크레덴셜 위변조 및 제출자 소유의 크레덴셜 여부를 검증하는 주체</li>
        <li>사용자: 본 약관에 따라 회사가 제공하는 쯩 서비스를 이용하는 주체</li>
        <li>사용자 저장 데이터: 사용자가 쯩 서비스를 이용하기 위한 목적으로 생성하여 모바일 기기에 저장하고 있는 개인 데이터로서 DID, DID 개인 키, 쯩 서비스를 통해 발급받은 크레덴셜, 크레덴셜 안의 개인 신원 정보 등을 포함합니다.</li>
        <li>블록체인 저장 데이터: 제출된 크레덴셜의 검증을 목적으로 블록체인에 기록되는 데이터를 의미하며 DID, DID 공개 키, 크레덴셜 상태값 등을 포함합니다(사용자의 신원 등에 관한 개인 정보는 블록체인 기록 대상이 아닙니다).</li>
      </ol>
      <h2>제3조 (약관의 효력 및 변경)</h2>
      <ol>
        <li>① 회사는 본 약관의 내용을 사용자가 알 수 있도록 쯩 서비스 내 또는 별도의 연결화면에 게시합니다.</li>
        <li>②	회사는 ‘약관의 규제에 관한 법률’ ‘정보통신망이용촉진 및 정보보호 등에 관한 법률’ 등 관련 법령을 위배하지 않는 범위에서 본 약관을 개정할 수 있습니다.</li>
        <li>③	회사가 본 약관을 개정할 경우에는 적용일자 및 개정 내용, 개정 사유 등을 명시하여 제1항의 방식에 따라 그 개정약관을 적용일 7일 이전부터 공지합니다.</li>
        <li>④	회사는 전항에 따라 개정약관을 공지하면서 사용자가 개정약관에 대해 동의 또는 거부의 의사표시를 하지 않으면 동의한 것으로 볼 수 있다는 내용도 함께 공지를 하며, 사용자가 개정약관 시행일까지 거부의 의사표시를 하지 않는다면 개정약관에 동의한 것으로 볼 수 있습니다. 사용자가 개정약관에 대해 동의하지 않는 경우 회사 또는 사용자는 서비스 이용계약을 해지할 수 있습니다.</li>
      </ol>
      <h2>제4조 (약관 외 준칙)</h2>
      <ol>
        <li>① 회사는 필요한 경우 개별 쯩 서비스에 관하여 적용될 사항을 위해 개별약관이나 이용준칙을 정하여 운영할 수 있으며, 해당 내용은 쯩 서비스 내 또는 별도의 홈페이지 등을 통해 공지합니다.</li>
        <li>② 본 약관에서 정하지 아니한 사항과 본 약관의 해석에 관하여는 「약관의 규제에 관한 법률」,「정보통신망이용촉진 및 정보보호 등에 관한 법률」 등 관련 법령 또는 상 관례에 따릅니다.</li>
      </ol>
      <h2>제5조 (쯩 서비스 이용 절차)</h2>
      <ol>
        <li>①	회사는 사용자가 되고자 하는 자가 본 약관 및 이용하고자 하는 쯩 서비스의 종류에 따라 개별 약관이 존재하는 경우 그 개별 약관에 동의하고 본인확인을 거쳐 PIN 또는 생체인식 정보 등록을 마친 후, 쯩 서비스의 이용을 승낙함으로써 쯩 서비스 이용계약을 체결하는 것을 원칙으로 합니다.</li>
        <li>②	사용자는 쯩 서비스 이용에 필요한 정보를 회사 또는 발급기관 또는 이용기관에 제공함으로써 쯩 서비스를 이용할 수 있습니다. 예를 들어 금융 실명인증 서비스의 경우 사용자는 휴대폰 본인확인 정보, 신분증 정보, 계좌 정보 (계좌 번호, 비밀번호) 등을 발급기관인 금융기관에  제공하여 인증을 받음으로써 이용할 수 있습니다.</li>
        <li>③	쯩 서비스 이용에 필요한 정보는 서비스 내용 변경, 회사나 발급기관 또는 이용기관의 정책 변경 및 관련 법령에 따라 달라질 수 있습니다.</li>
        <li>④	사용자가 제1항이나 제2항에서 요청하는 정보를 사실대로 정확하게 제공하지 않은 경우 사전적 또는 사후적으로 서비스 이용에 제한을 받을 수 있습니다.</li>
        <li>⑤	타인의 명의나 정보를 도용하여 이용신청을 한 사용자는 사전적 또는 사후적으로 회사가 제공하는 서비스 전체 또는 일부가 제한될 수 있으며 관계법령에 따라 처벌을 받을 수 있습니다.</li>
        <li>⑥	회사는 사용자가 본 조의 절차에 위반하여 이용신청을 한 경우 이로 인해 발생한 피해에 대하여 책임을 지지 않습니다.</li>
        <li>⑦	회사는 사용자의 신청에 대하여 승낙함을 원칙으로 합니다. 다만, 회사는 다음 각 호의 어느 하나에 해당하는 이용 신청에 대해서는 승낙을 거절할 수 있습니다.
          <ol class="sub">
            <li>본 조의 절차에 위반하여 이용신청을 한 경우</li>
            <li>기재 내용에 허위, 기재누락, 오기 등이 있거나 이용신청 요건을 충족하지 못한 경우</li>
            <li>회사가 서비스를 제공하지 않은 국가에서 비정상적이거나 우회적인 방법을 통해 서비스를 이용하는 경우</li>
            <li>사회의 안녕과 질서 또는 미풍양속을 저해할 목적으로 신청한 경우</li>
            <li>본 약관에 위배되거나 위법 또는 부당한 목적의 이용신청임이 확인된 경우</li>  
            <li>영리를 추구할 목적으로 쯩 서비스를 이용하고자 하는 경우</li>    
            <li>그 밖에 각 호에 준하는 사유로서 승낙이 부적절하다고 판단되는 경우</li>    
          </ol>
        </li>
        <li>⑧ 회사는 다음 각 호의 어느 하나에 해당하는 경우 그 사유가 해소될 때까지 승낙을 유보할 수 있습니다.
          <ol class="sub">
            <li>회사의 설비에 여유가 없거나, 특정 모바일 기기의 지원이 어렵거나, 기술적 장애가 발생한 경우</li>
            <li>그 밖의 각 호에 준하는 사유로서 이용신청의 승낙이 어렵다고 판단되는 경우</li>
          </ol>
        </li>
        <li>⑨ 회사는 일부 서비스 이용에 대해서 사용자에게 수수료를 부과할 수 있습니다. 회사는 건당 과금, 무료이용 조건 설정, 월 이용료 등 수수료 정책을 사전에 앱과 홈페이지를 통해 고지하고 시행합니다.</li>
      </ol>
      <h2>제6조 (개인정보의 보호 및 사용)</h2>
      <ol>
        <li>① 회사는 관련 법령이 정하는 바에 따라 사용자의 개인정보를 보호하기 위해 노력하며, 개인정보의 보호 및 사용에 대해서는 관련 법령에 따릅니다.</li>
        <li>② 사용자 저장 데이터는 사용자의 모바일 기기에 안전한 방식으로 저장됩니다. 회사에서는 사용자의 별도 동의 없이는 사용자 저장 데이터를 수집, 보관, 관리하지 않으며, 회사는 사용자 저장 데이터에 대한 폐기, 복구 의무가 없습니다.</li>
        <li>③ 쯩 사용자가 크레덴셜을 받기 위해 발급기관에 직접 제출하는 개인정보의 수집 및 보관에 대한 책임은 발급기관에 있으며 회사는 이에 대한 일체의 책임을 부담하지 않습니다.</li>
        <li>④ 쯩 사용자가 검증 받기 위해 이용기관에 직접 제출하는 크레덴셜 및 개인정보의 수집, 보관 및 활용에 대한 책임은 이용기관에 있으며 회사는 이에 대한 일체의 책임을 부담하지 않습니다.</li>
        <li>⑤ 회사는 사용자의 귀책사유로 사용자 저장 데이터 및 개인정보가 유출되어 발생한 피해에 대하여 책임을 지지 않습니다.</li>
      </ol>
      <h2>제7조 (자기주권형 개인정보 관리)</h2>
      <ol>
        <li>① 쯩 서비스는 정보 주체인 사용자가 크레덴셜와 크레덴셜에 담긴 신원 등에 관한 개인정보를 사용자 저장 데이터 형태로 본인 소유의 모바일 단말기에 직접 저장, 관리, 제출하는 자기주권형 신원증명 서비스입니다. 사용자는 사용자 저장 데이터의 안전한 보관 및 관리에 대한 책임이 있으며 제3자에게 데이터가 유출되거나 도용되지 않도록 할 책임이 있습니다. 사용자의 고의 또는 과실로 사용자 저장 데이터가 유출 또는 도용되어 발생한 책임에 대해 회사는 책임을 부담하지 않습니다.</li>
        <li>② 사용자 모바일 기기의 바이러스 감염, 해킹, 비밀번호 유출 또는 생체인식 오류 등 각종 원인으로 사용자가 사용자 저장 데이터를 이용할 수 없게 되거나 제3자에게 노출된 경우 회사는 이에 대한 일체의 책임을 부담하지 않습니다. 단, 회사의 고의 또는 중과실의 경우에는 그렇지 아니합니다.</li>
        <li>③ 쯩 서비스는 회사가 관리하는 별도의 회원 계정 체계가 없습니다. 사용자는 별도의 해지 절차 없이 쯩 애플리케이션을 삭제함으로써 쯩 서비스의 이용을 중단할 수 있으며 사용자의 모바일 기기에 저장되었던 사용자 저장 데이터는 영구 삭제됩니다. </li>
        <li>④ 모바일 기기의 변경 혹은 분실 시 사용자 저장 데이터는 복원 또는 복구가 불가능하며 사용자는 쯩 애플리케이션을 재설치 후 크레덴셜을 재발급하여야 합니다.</li>
        <li>⑤ 회사는 제3항과 제4항의 경우 사용자 저장 데이터의 복원 또는 폐기에 대한 책임을 지지 않습니다.</li>
        <li>⑥ 사용자는 블록체인 저장 데이터에 대한 소유권, 이동권, 삭제권 등의 권리를 주장할 수 없습니다.</li>
      </ol>
      <h2>제8조 (회사의 의무)</h2>
      <ol>
        <li>①	회사는 관련 법령 및 본 약관에서 정하는 권리의 행사 및 의무의 이행을 신의에 따라 성실하게 준수합니다.</li>
        <li>②	회사는 사용자가 안전하게 쯩 서비스를 이용할 수 있도록 개인정보(사용자 저장 데이터 포함)보호를 위해 필요한 보안시스템을 갖추어야 합니다. 회사는 사용자의 개인정보가 사용자의 동의없이 제3자에게 제공되지 않도록 합니다.</li>
        <li>③ 회사는 계속적이고 안정적인 쯩 서비스의 제공을 위하여 최선을 다하여 노력합니다. 서비스 또는 설비에 장애가 생겼을 경우 천재지변, 비상사태, 현재의 기술로는 해결이 불가능한 장애나 결함 등 부득이한 사유가 없는 한 지체 없이 이를 수리 또는 복구하도록 최선의 노력을 다합니다.</li>
        <li>④ 회사는 쯩 서비스 이용과 관련하여 사용자가 장애나 개선사항에 대해 회사에 전달할 수 있는 방법을 서비스 내에 고지합니다.</li>
      </ol>
      
      <h2>제9조 (사용자의 의무)</h2>
      <ol>
        <li>① 사용자는 본 약관 및 개정약관, 회사가 쯩 서비스 화면에서 고지하는 내용을 확인하고 준수하여야 하며, 본 약관, 개정약관 및 고지 내용을 위반하거나 이행하지 아니하여 발생하는 손실 및 손해에 대하여 책임을 부담합니다.</li>
        <li>② 사용자는 쯩 서비스 내에서 발급기관 또는 이용기관이 제공하는 상품 등을 가입 또는 구매 시 스스로의 책임 하에 가입 또는 구매하여야 하며, 회사는 상품 등의 내용과 거래 조건에 대해서 어떠한 보증이나 책임도 부담하지 않습니다.</li>
        <li>③ 사용자는 자신의 명의가 도용되거나 제3자에게 부정하게 사용된 것을 인지한 경우 즉시 그 사실을 회사에 통보하여야 합니다.</li>
        <li>④ 사용자는 쯩 서비스를 이용하여 얻은 정보를 회사의 사전 동의 없이 영리목적으로 이용하거나 제3자에게 이용하게 하는 행위를 할 수 없으며, 쯩 서비스의 이용권한 및 기타 이용계약상 지위를 타인에게 양도, 증여할 수 없습니다.</li>
        <li>⑤ 사용자는 회사가 쯩 서비스를 안전하게 제공할 수 있도록 회사에 협조하여야 하며, 회사가 사용자의 본 약관 위반행위를 발견하여 회원에게 해당 위반행위에 대하여 소명을 요청할 경우 회사의 요청에 적극 응하여야 합니다.</li>      
      </ol>
      <h2>제10조 (사용자의 금지행위)</h2>
      <ol>
        <li>① 회사는 쯩 서비스의 신뢰성을 제공하고 안전한 쯩 서비스 이용이 이루어 질 수 있도록 아래와 같은 사용자의 행위를 금지합니다.
          <ol class="sub">
            <li>회사가 제공하는 쯩 서비스 이용방법에 의하지 아니하고 비정상적인 방법으로 쯩 서비스를 이용하거나 시스템에 접근하는 행위</li>
            <li>회사에서 제공하는 서비스를 본래의 이용 목적 이외의 용도로 사용하는 행위</li>
            <li>타인의 명의, 휴대폰 정보, 계좌 정보, 생체인식 정보, 신분증 등을 도용하여 쯩 서비스를 이용신청하거나 이용하는 행위</li>
            <li>회사 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위</li>
            <li>회사 및 기타 제3자의 저작권, 초상권 등 지적재산권에 대한 침해행위</li>
            <li>회사가 제공하는 쯩 서비스를 이용하여 얻은 회사 또는 제3자의 정보를 정보 소유자의 사전 동의 없이 복제 또는 유통시키거나 상업적으로 이용하는 행위</li>
            <li>회사의 동의 없이 영리, 영업, 광고, 정치활동, 불법선거운동 등을 목적으로 서비스를 이용하는 행위</li>
            <li>기타 공공질서 및 미풍양속을 위반하거나 불법적, 부당한 행위 및 법령에 위배되는 행위</li>
          </ol>
        </li>
        <li>② 회사는 사용자가 본 조의 금지행위를 행하는 경우 제11조에 따라 쯩 서비스 이용을 제한할 수 있으며, 회사는 필요한 경우 사용자의 금지행위 사실을 관련 정부기관 또는 사법기관에 통지할 수 있습니다.</li>
      </ol>
      
      <h2>제11조 (사용자에 대한 서비스 이용 제한)</h2>
      <ol>
       <li>① 사용자는 다음 각 호의 어느하나에 해당하는 사유가 발생하는 경우 사용자의 서비스 이용제한 및 기타 조치를 포함한 이용제한 조치를 할 수 있습니다.
        <ol class="sub">
          <li>본 약관 제5조 제4항 및 제5항에 해당하는 경우</li>
          <li>본 약관 제9조의 사용자의 의무를 이행하지 않은 경우</li>
          <li>본 약관 제10조 제2항에 해당하는 경우</li>
          <li>기타 사용자가 본 약관을 위반하거나 부당한 행위를 한 경우</li>
        </ol>
       </li>
       <li>② 제1항의 이용제한이 정당한 경우에 회사는 이용제한으로 인하여 사용자가 입은 손해를 배상하지 않습니다.</li>
      </ol>
      
      <h2>제12조 (서비스의 변경 및 중단)</h2>
      <ol>
        <li>① 회사는 다음 각 호의 경우에는 서비스의 전부 또는 일부를 일시 중단할 수 있습니다. 이 경우 회사는 사전에 그 정지의 사유와 기간을 쯩 초기화면이나 서비스 공지사항 등에 공지합니다. 다만, 사전에 공지할 수 없는 부득이한 사정이 있는 경우 사후에 공지할 수 있습니다.
          <ol class="sub">
            <li>회사 또는 발급기관 또는 이용기관의 시스템 정기점검, 서버의 증설 및 교체, 네트워크의 불안정 등의 시스템 운영상 필요한 경우</li>
            <li>정전, 서비스 설비의 장애, 서비스 이용 폭주, 기간통신사업자의 설비 보수 또는 장애 및 점검 등으로 인하여 정상적인 서비스 제공이 불가능한 경우</li>
            <li>본 약관 제10조 제2항에 해당하는 경우천재지변, 전쟁, 폭동, 테러, 해킹, DDOS, 또는 이에 준하는 국가비상사태 등 회사가 통제할 수 없는 상황이 발생한 경우</li>
          </ol>
        </li>
        <li>② 쯩 서비스는 관련 법령이나 정책 변경에 따라 달라질 수 있습니다. 회사는 쯩 서비스 제공을 위해 계약을 체결하고 있는 발급기관 또는 이용기관 등의 시스템 운영 상황이나 계약 체결 상황에 따라서 쯩 서비스 운영상 상당한 이유가 있는 경우에는 쯩 서비스의 전체 또는 일부의 제공을 일시적으로 중단할 수 있습니다.</li>
        <li>③ 회사는 파산 신청, 기업회생절차 개시 신청, 현저한 수익 악화 등 정상적인 영업활동이 곤란하여 쯩 서비스를 지속할 수 없는 때에는 사용자에게 이를 통지한 날로부터 30일이 경과한 때부터 쯩 서비스를 중단할 수 있습니다.</li>
        <li>④ 회사는 원활한 서비스 제공을 위해 운영상 또는 기술상의 필요에 따라 서비스를 변경할 수 있으며, 변경 전에 해당 내용을 쯩 서비스 내에 공지합니다. 다만, 버그․오류 등의 수정이나 긴급 업데이트 등 부득이하게 변경할 필요가 있는 경우 또는 중대한 변경에 해당하지 않는 경우에는 사후에 공지할 수 있습니다.</li>
      </ol>
      
      <h2>제13조 (정보의 제공 및 광고의 게재)</h2>
      <ol>
        <li>① 회사는 사용자가 서비스 이용을 위해 필요하다고 인정되는 다양한 정보를 쯩 서비스 내 혹은 별도의 홈페이지에 게시하거나 기타 방법으로 사용자에게 제공할 수 있습니다.</li>
        <li>② 회사는 본 서비스 등을 유지하기 위하여 광고를 게재할 수 있으며, 사용자는 서비스 이용 시 노출되는 광고게재에 대하여 동의합니다.</li>
        <li>③ 쯩 내에 노출되는 제3자가 주체인 제2항의 광고에 사용자가 참여하거나 거래를 함으로써 발생하는 손실과 손해에 대해서 회사는 어떠한 책임도 부담하지 않습니다.</li>
      </ol>
      
      <h2>제14조 (손해배상)</h2>
      <ol>
        <li>① 회사 또는 사용자는 본 약관을 위반하여 상대방에게 손해를 입힌 경우에는 그 손해를 배상할 책임이 있습니다. 다만, 고의 또는 과실이 없는 경우에는 그러하지 아니 합니다.</li>
        <li>② 사용자가 서비스를 이용함에 있어 행한 불법행위 또는 본 약관을 위반한 행위로 회사가 당해 사용자 외의 제3자로부터 손해배상청구 또는 소송 등 각종 이의제기를 받는 경우 당해 사용자는 자신의 책임과 비용으로 회사를 면책시켜야 하며, 회사가 면책되지 못한 경우 당해 사용자는 그로 인하여 회사에 발생한 모든 손해를 배상할 책임이 있습니다.</li>
      </ol>
      
      <h2>제15조 (서비스에 대한 위험 고지 및 확인)</h2>
      <ol>
        <li>① 회사는 금융혁신지원 특별법 제 20조 제1항 및 제2항에 따라 사용자에게 쯩 서비스에 대하여 다음 사항을 안내합니다.
          <ol class="sub">
            <li>쯩 서비스 중 금융기관이 발급하는 금융 실명인증 서비스는 금융혁신지원 특별법에 의거하여 시험 운영 중인 혁신금융서비스로서 그 서비스 제공 내용과 관련하여 예상하지 못한 위험이 발생할 수 있습니다.</li>
            <li>혁신금융서비스 지정기간이 종료되거나 지정조건이 변경되었을 경우 해당 금융 실명인증 서비스의 전체 또는 일부가 중단 또는 변경될 수 있습니다.</li>
            <li>사용자는 본 약관에 동의함으로써, 금융혁신지원 특별법에 따라 시범운영 중인 쯩 서비스를 제공받는 것에 동의한 것으로 간주됩니다.</li>
            <li>본항 제1호 및 제2호에도 불구하고, 서비스 위험의 발생으로 인해 회사가 사용자에게 부담하는 법률적 책임에 영향을 미치지 않습니다. </li>
          </ol>
        </li>
      </ol>
      
      <h2>제16조 (책임보험)</h2>
      <ol><li>① 금융혁신지원 특별법 제27조에 의거 회사는 해당 혁신금융서비스의 제공 및 중단 등으로 인하여 사용자에게 손해가 발생하는 경우를 대비하여 책임보험에 가입하였습니다. 다만, 회사의 고의 또는 과실이 없는 경우에는 회사가 책임을 부담하지 않습니다.</li></ol>
      
      <h2>제17조 (분쟁 처리 및 조정 방안)</h2>
      <ol>
       <li>① 회사는 해당 혁신금융서비스의 이용 기간 동안 금융혁신지원 특별법 제28조에 따른 분쟁 처리 및 조정을 위해 아래와 같은 조항을 마련하고 이를 근거로 분쟁을 해결합니다.
        <ol class="sub">
          <li>사용자는 회사에 정당한 의견이나 불만사항을 제기하고 쯩에서 제공되는 금융 실명인증 서비스로 인한 손해배상 청구를 하는 경우에는 회사는 해당 청구가 접수된 날부터 30일 이내에 요청한 내용을 파악하여 조사 또는 처리 결과를 사용자에게 안내합니다. 단, 회사는 효율적인 처리를 위하여 사용자에게 관련 자료 제출 등을 요구할 수 있습니다.</li>
          <li>사용자는 회사의 고객관리팀에 쯩에서 제공되는 금융 실명인증 서비스 이용과 관련한 분쟁 처리를 요구할 수 있습니다.
            <br />- 전화번호 : (02) 2138-7026
            <br />- 이메일 : zzeung@iconloop.com
          </li>
          <li>사용자는 쯩에서 제공되는 금융 실명인증 서비스에 대하여 이의가 있는 경우 위 절차에 따라 회사에 분쟁 처리 신청을 하거나 금융감독원의 금융분쟁조정위원회에 직접 분쟁 조정 신청을 할 수 있습니다. 분쟁조정의 신청 등에 관하여는 「금융위원회의 설치 등에 관한 법률」 제51조부터 제53조까지, 제53조의2 및 제54조부터 제57조까지의 규정에 따릅니다.</li>
        </ol>
       </li>
      </ol>
      
      <h2>제18조 (회사의 면책)</h2>
      <ol>
        <li>① 회사는 사용자와 발급기관 또는 이용기관 상호간의 거래에 대한 어떠한 책임도 부담하지 않으며, 회사의 귀책사유가 아닌 사유로 발생한 서비스의 이용장애에 대하여 책임을 부담하지 않습니다. 또한 회사는 사용자 또는 발급기관 또는 이용기관의 귀책사유로 인하여 발생한 손해에 대하여 회사의 귀책 사유가 없는 경우 책임을 부담하지 않습니다.</li>
        <li>② 회사는 사용자가 쯩 서비스를 이용하여 기대하는 수익을 달성하지 못했거나 상실한 것에 대해서 책임을 지지 않으며, 그 밖에 쯩 서비스를 통하여 얻은 정보로 인하여 발생한 손해에 대하여도 책임을 부담하지 않습니다.</li>
        <li>③ 사용자가 자신의 개인정보, 사용자 저장 데이터, 비밀번호 (생체등록 정보 포함), 쯩 서비스 이용 관련 정보 등을 타인에게 제공하거나, 사용자의 관리소홀로 유출됨으로써 발생하는 피해에 대해서 회사는 책임을 지지 않습니다.</li>
        <li>④ 회사는 사용자가 본인의 모바일 기기 내에 있는 사용자 저장 데이터를 삭제하여 서비스 이용에 제한이 되는 경우  회사는 이에 대해 책임을 지지 않습니다. </li>
        <li>⑤ 회사는 발급기관이 크레덴셜을 발급하는 과정에서 발급기관의 고의 또는 과실로 인한 서비스의 이용장애 및 이로 인해 발생한 피해에 대해서 책임을 부담하지 않습니다.</li>
        <li>⑥ 회사는 이용기관이 크레덴셜을 검증하는 과정에서 이용기관의 고의 또는 과실로 인한 서비스의 이용장애 및 이로 인해 발생한 피해에 대해서 책임을 부담하지 않습니다.</li>
        <li>⑦ 회사는 서비스용 설비의 보수, 교체, 정기점검, 공사 등 기타 이에 준하는 사유로 발생한 손해에 대하여 책임을 지지 않습니다. 다만, 회사의 고의 또는 과실에 의한 경우에는 그러하지 아니합니다.</li>
        <li>⑧ 사용자가 모바일 기기의 변경, 모바일 기기의 번호 변경, 운영체제(OS) 버전의 변경, 해외 로밍, 통신사 변경 등으로 인해 콘텐츠 전부나 일부의 기능을 이용할 수 없는 경우 회사는 이에 대해 책임을 지지 않습니다.</li>
      </ol>
      

      <h2>제19조 (재판권 및 준거법)</h2>
      <p>이 약관은 대한민국 법률에 따라 규율되고 해석됩니다. 회사와 사용자 간에 발생한 분쟁으로 소송이 제기되는 경우에는 법령에 정한 절차에 따른 법원을 관할 법원으로 합니다.</p>
      
      <br />
      <p>v1.1.0<br />
      개정일자 : 2020. 5.27<br />
      시행일자 : 2020. 6.4
      </p>
    `,
  },
  {
    title: "증명서 이용약관",
    desc: `
      <h1>broof 이용약관</h1>
      <h2><제1장 총칙></h2>
      <h2>제1조 (목적)</h2>
      <p>
        본 약관은 아이콘루프(이하 회사라 함)가 제공하는 broof 이용과 관련하여, 회사와
        회원 및 이용자와의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을
        목적으로 합니다.
      </p>

      <h2>제2조 (용어의 정의)</h2>
      <ol>
        <li>
          ① 본 약관에서 사용하는 용어의 정의는 다음과 같습니다.
          <ol class="sub">
            <li>
              서비스 : 회사가 웹사이트를 통해 제공하는 블록체인 증서 발급 서비스
              broof를 말합니다.
            </li>
            <li>
              유료서비스 : 회사가 제공하는 서비스 중 이용자가 회사에 일정 금액을
              결제하거나, 이용자가 회사 또는 제3자와의 거래 내지 약정 조건을 수락하는
              경우에 이용할 수 있는 회사의 서비스를 말합니다.
            </li>
            <li>이용자 : 서비스를 이용하는 회원 또는 비회원을 말합니다.</li>
            <li>
              회원 : 서비스에 접속하여 본 약관에 따라 회사와 이용계약을 체결하고
              회사가 제공하는 서비스를 이용하는 자를 말합니다.
            </li>
            <li>
              비회원 : 서비스에 가입하지 않고 서비스가 제공하는 정보 및 서비스를
              이용하는 자를 말합니다.
            </li>
            <li>유료회원 : 유료서비스를 이용하는 회원을 말합니다.</li>
            <li>
              개인정보 : 서비스를 이용하기 위해 회원 또는 비회원이 입력하여 서비스에
              기재되는 기관명, 성명, 이메일 주소 등 회원 또는 비회원의 개인정보를
              말합니다.
            </li>
            <li>발급기관 : 수령자에게 증서를 발급하는 회원을 말합니다.</li>
            <li>
              수령자 : 발급기관으로부터 증서를 발급받을 수 있는 권한을 부여받고 이를
              발급받으려는 이용자를 말합니다.
            </li>
            <li>
              발급 이용권 : 증서 발급을 요청하기 위해 구매하는 이용권을 말합니다.
            </li>
            <li>웹사이트 : https://broof.io 를 의미합니다.</li>
          </ol>
        </li>
        <li>
          이 약관에서 사용하는 용어의 정의는 제1항에서 정하는 것을 제외하고는
          관련법령에서 정하는 바에 의하며, 관련 법령에서 정하지 않는 것은 일반적인
          상관례에 의합니다.
        </li>
      </ol>

      <h2>제3조 (약관의 게시와 개정)</h2>
      <ol>
        <li>
          ① 회사는 이 약관의 내용과 상호, 대표자의 성명, 영업소 소재지 주소,
          통신판매업 신고번호, 사업자등록번호, 연락처 등을 이용자가 알 수 있도록
          서비스 초기화면에 게시합니다. 다만, 약관의 내용은 이용자가 연결화면을 통하여
          볼 수 있도록 할 수 있습니다.
        </li>
        <li>
          ② 회사는 전자상거래 등에서의 소비자보호에 관한 법률, 소비자기본법, 약관의
          규제에 관한 법률, 전기통신기본법, 전기통신사업법, 정보통신망 이용촉진 및
          정보보호 등에 관한 법률 등 관련 법령을 위배하지 않는 범위에서 이 약관을
          개정할 수 있습니다.
        </li>
        <li>
          ③ 회사가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과
          함께 개정약관의 적용일자 7일전부터 적용일자 전일까지 서비스 초기화면에
          공지합니다. 단 회원의 권리, 의무에 중대한 영향을 주는 변경의 경우에는
          적용일자 30일 전부터 공지하도록 합니다.
        </li>
        <li>
          ④ 회원은 변경된 약관에 대해 거부할 권리가 있습니다. 회사가 제3항의 공지 또는
          통보를 하면서 개정 약관의 적용/시행일까지 회원이 거부 의사를 표시하지 아니할
          경우 약관의 개정에 동의한 것으로 간주한다는 내용을 고지하였으나, 회원이
          명시적으로 약관 변경에 대한 거부의사를 표시하지 아니하면, 회사는 회원이
          적용/시행일자 부로 변경 약관에 동의한 것으로 간주합니다. 개정/변경 약관에
          대하여 거부의사를 표시한 회원은 계약의 해지 또는 회원 탈퇴를 선택할 수
          있습니다.
        </li>
      </ol>

      <h2>제4조 (약관의 해석)</h2>
      <ol>
        <li>
          ① 회원이 회사와 개별서비스에 대한 계약을 체결하는 경우 회사는 개별 서비스에
          대한 이용약관 또는 운영정책 등(이하 운영정책 등)을 둘 수 있으며, 해당 내용이
          본 약관과 상충되는 경우 개별서비스에 대한 운영정책 등이 본 약관보다
          우선합니다.
        </li>
        <li>
          ② 이 약관에서 규정하지 않은 사항에 관해서는 약관의 규제에 관한 법률,
          전기통신기본법, 전기통신사업법, 정보통신망 이용촉진 및 정보보호 등에 관한
          법률 등의 관계법령에 따릅니다.
        </li>
      </ol>

      <h2>제5조 (이용계약의 성립)</h2>
      <ol>
        <li>
          ① 회사와 회원 사이의 서비스 이용계약(이하 이용계약이라 함)은 서비스를
          이용하고자 하는 자(이하 가입신청자라 함)의 회원가입의 이용신청 및 본 약관과
          개인정보 처리방침의 내용에 대한 동의에 대한 회사의 이용승낙으로 성립합니다.
        </li>
        <li>
          ② 본 약관 및 개인정보처리방침에 대한 동의의 의사표시는 가입신청자가 가입신청
          당시 회원가입 버튼을 누름으로써 성립합니다. 이와 동시에 위 가입신청자가 본
          약관 및 개인정보처리방침에 대하여 동의한 것으로 간주합니다.
        </li>
        <li>
          ③ 회사는 가입신청자의 신청에 대하여 서비스 이용을 승낙함을 원칙으로 합니다.
          다만, 회사는 다음 각 호에 해당하는 신청에 대하여는 승낙을 하지 않거나 사후에
          이용계약을 해지할 수 있습니다.
          <ol class="sub">
            <li>
              가입신청자가 이 약관에 의하여 이전에 회원자격을 상실한 적이 있는 경우
            </li>
            <li>실명이 아니거나 타인의 명의를 이용한 가입신청의 경우</li>
            <li>
              허위의 정보를 기재하거나, 회사가 제시하는 내용을 기재하지 않은 경우
            </li>
            <li>
              만 14세 미만 아동이 정보통신망 이용촉진 및 정보보호 등에 관한 법률에서
              정한 개인정보 입력 시 법정대리인의 동의를 얻지 아니한 경우
            </li>
            <li>
              가입신청자의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한 제반
              사항을 위반하며 신청하는 경우
            </li>
          </ol>
        </li>
        <li>
          ④ 회사는 서비스관련설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는
          경우에는 승낙을 유보할 수 있습니다.
        </li>
        <li>
          ⑤ 회사가 제2항, 제3항 및 제4항에 따라 회원가입신청을 승낙하지 아니하거나
          유보한 경우, 회사는 원칙적으로 그 사실을 가입신청자에게 알리도록 합니다.
        </li>
        <li>
          ⑥ 이용계약의 성립 시기는 회사의 승낙이 가입신청자에게 도달한 시점으로
          합니다.
        </li>
        <li>
          ⑦ 회사는 회원에 대해 회사정책에 따라 등급별로 구분하여 이용시간, 이용횟수,
          서비스 메뉴 등을 세분하여 이용에 차등을 둘 수 있습니다.
        </li>
        <li>
          ⑧ 이 약관은 회원이 이 약관에 동의한 날로부터 회원 탈퇴 시까지 적용하는 것을
          원칙으로 합니다. 단, 이 약관의 일부 조항은 회원이 탈퇴 후에도 유효하게
          적용될 수 있습니다.
        </li>
      </ol>

      <h2>제6조 (이용자 정보의 제공)</h2>
      <ol>
        <li>
          ① 회원으로 가입하여 서비스를 이용하고자 하는 이용자는 이메일, 이름 등 회사가
          정한 가입 양식에 따라 정보를 제공하여야 합니다.
        </li>
        <li>
          ② 이용자가 제1항에서 정한 인증을 거치지 않은 경우 서비스 이용이 제한될 수
          있으며, 실명으로 등록하지 않은 이용자 또는 회원은 일체의 권리를 주장할 수
          없습니다.
        </li>
        <li>
          ③ 타인의 명의를 도용하여 이용신청을 한 회원의 모든 ID는 삭제되며, 관계법령에
          따라 처벌을 받을 수 있습니다.
        </li>
      </ol>

      <h2>제7조 (개인 정보의 보호 및 관리)</h2>
      <ol>
        <li>
          ① 회사는 관계 법령이 정하는 바에 따라 계정정보를 포함한 이용자의 개인정보를
          보호하기 위하여 노력합니다. 이용자의 개인정보 보호 및 사용에 대해서는 회사가
          별도로 고지하는 개인정보 처리방침에 따릅니다. 다만, 회사가 제공하는 공식
          서비스 사이트 이외의 링크된 사이트에서는 회사의 개인정보처리방침이 적용되지
          않습니다.
        </li>
        <li>
          ② 회사는 이용자의 귀책사유로 인하여 노출된 이용자의 계정정보를 포함한 모든
          정보에 대해서는 일체의 책임을 지지 않습니다.
        </li>
      </ol>

      <h2>제8조 (회원의 계정 및 비밀번호)</h2>
      <ol>
        <li>
          ① 회원은 서비스의 원활한 이용 및 회원의 정보보호, 서비스 이용안내 등의
          편의를 위하여 이용자가 선정한 이메일 주소를 계정으로 사용합니다. 다만,
          회사는 회원의 계정이 반사회적이거나 미풍양속을 해치거나 또는 운영자로 오인할
          우려가 있는 경우 등에는 해당 계정의 사용을 거부하거나 제한할 수 있습니다
        </li>
        <li>
          ② 회사는 계정정보를 통하여 당해 회원의 서비스 이용가능여부 등 제반 이용자
          관리 업무를 수행합니다.
        </li>
        <li>
          ③ 회원은 자신의 계정정보를 선량한 관리자로서의 주의의무를 다하여 관리 하여야
          합니다. 회원이 본인의 계정정보를 소홀히 관리하거나 제3자에게 이용을
          승낙함으로써 발생하는 손해에 대하여는 회원에게 책임이 있습니다.
        </li>
        <li>
          ④ 회원은 회사가 정한 기준을 충족하는 범위 내에서 자유롭게 비밀번호를 정할 수
          있으며, 정해진 비밀번호는 회원이 원하는 경우 언제든지 변경이 가능합니다.
        </li>
        <li>
          ⑤ 회원은 서비스의 이용을 위하여 사용하는 비밀번호에 대한 보호 및 관리 책임을
          부담합니다. 다만, 회사는 보안 등을 이유로 회원에게 정기적 또는 비정기적으로
          비밀번호의 변경을 권고할 수 있습니다.
        </li>
      </ol>

      <h2>제9조 (회원에 대한 통지)</h2>
      <ol>
        <li>
          ① 회사가 회원에 대한 통지를 하는 경우 회원이 회원가입시 지정한 이메일 주소로
          할 수 있습니다.
        </li>
        <li>
          ② 회사는 회원 전체에 대한 통지의 경우 7일 이상 회사의 홈페이지 또는 공지사항
          게시판에 게시함으로써 제1항의 통지에 갈음할 수 있습니다.
        </li>
      </ol>

      <h2>제10조 (서비스의 제공시간 및 중지)</h2>
      <ol>
        <li>
          ① 회사는 회원의 회원가입을 승낙한 때부터 서비스를 개시합니다. 단, 일부
          서비스의 경우, 회사의 필요에 따라 지정된 일자부터 서비스를 제공할 수
          있습니다.
        </li>
        <li>
          ② 제1항 단서의 경우 회사는 그 내용 및 시간을 홈페이지에 공지합니다. 다만,
          회사가 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수
          있습니다.
        </li>
        <li>
          ③ 회사는 서비스의 제공에 필요한 경우 정기점검을 실시할 수 있으며,
          정기점검시간은 홈페이지에 공시한 바에 따릅니다.
        </li>
      </ol>

      <h2>제11조 (서비스의 내용 및 변경)</h2>
      <ol>
        <li>
          ① 이용자는 회사가 제공하는 서비스를 이 약관, 운영정책 등 회사가 정한 규칙에
          따라 이용할 수 있습니다.
        </li>
        <li>
          ② 회사가 이용자에게 제공하는 서비스에 대하여 회사는 제작, 변경, 유지, 보수에
          관한 포괄적인 권한을 가집니다.
        </li>
        <li>
          ③ 회사는 새로운 서비스 내용, 각종 버그 패치 등 서비스의 운영상 또는 기술상의
          필요한 경우, 제공하고 있는 서비스의 전부 또는 일부를 상시적으로 수정, 추가,
          폐지 등 변경할 수 있습니다.
        </li>
        <li>
          ④ 회사는 무료로 제공되는 서비스의 일부 또는 전부를 회사의 정책 기획이나
          운영상 또는 회사의 긴박한 상황 등 필요에 의해 수정, 중단, 변경할 수 있으며,
          이에 대하여 관련 법령상 특별한 규정이 없는 한 이용자에게 별도의 보상을 하지
          않습니다.
        </li>
        <li>
          ⑤ 회사는 다음 각호에 해당하는 경우 서비스의 전부 또는 일부를 제한하거나
          중지할 수 있습니다.
          <ol class="sub">
            <li>
              전시, 사변, 천재지변 또는 국가비상사태 등 불가항력적인 사유가 있는 경우
            </li>
            <li>
              정전, 제반 설비의 장애 또는 이용량의 폭주 등으로 정상적인 서비스 이용에
              지장이 있는 경우
            </li>
            <li>서비스용 설비의 보수 등 공사로 인한 부득이한 경우</li>
            <li>기타 회사의 제반 사정으로 서비스를 할 수 없는 경우</li>
          </ol>
        </li>
        <li>
          ⑥ 회사는 서비스가 변경되거나 중지된 원인이 회사의 고의 또는 중대한 과실로
          인한 경우를 제외하고는 서비스의 변경 및 중지로 발생하는 문제에 대해서 책임을
          부담하지 않습니다.
        </li>
      </ol>

      <h2>제12조 (정보의 제공 및 광고의 게재)</h2>
      <ol>
        <li>
          ① 회사는 이용자가 서비스 이용 중 필요하다고 인정되는 다양한 정보를 회사의
          홈페이지에 게시하거나 이메일 등을 이용하여 이용자에게 제공할 수 있습니다.
          다만, 이용자는 관련 법령에 따른 거래관련 정보, 고객센터 답변 등을 제외한
          정보의 전송에 대하여 언제든지 이메일 등을 통하여 수신거절의 의사표시를 할 수
          있습니다.
        </li>
        <li>
          ② 회사는 본 서비스 등을 유지하기 위하여 광고를 게재할 수 있으며, 이용자는
          서비스 이용 시 노출되는 광고게재에 대하여 동의합니다.
        </li>
        <li>
          ③ 회사가 제공하는, 제3자가 주체인, 제2항의 광고에 이용자가 참여하거나 교신
          또는 거래를 함으로써 발생하는 손실과 손해에 대해서 회사는 어떠한 책임도
          부담하지 않습니다.
        </li>
        <li>
          ④ 회사는 적법하게 수집한 이용자의 개인정보를 활용하여 제2항의 광고 등을
          메일주소 등을 활용하여 발송할 수 있으며, 이용자가 원하지 않는 경우에는
          언제든지 수신을 거부할 수 있습니다.
        </li>
      </ol>

      <h2>제13조 (권리의 귀속)</h2>
      <ol>
        <li>
          ① 서비스 및 서비스 내 회사가 제작한 콘텐츠 등에 대한 저작권 및 지적재산권은
          회사에 귀속됩니다.
        </li>
        <li>
          ② 이용자는 서비스를 이용함으로써 얻은 정보 중 회사에게 지적재산권이 귀속된
          정보를 회사의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여
          영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다.
        </li>
      </ol>

      <h2>제14조 (회원의 탈퇴)</h2>
      <ol>
        <li>
          ① 회원은 홈페이지에 개제된 회사 연락처에 연락하거나 서비스 내 탈퇴 기능을
          통해 탈퇴 신청을 할 수 있으며, 회사는 관련법령 등에서 정하는 바에 따라 이를
          즉시 처리하여야 합니다.
        </li>
        <li>
          ② 다음 각호에 해당하는 경우를 제외하고, 이용자의 모든 정보는 탈퇴 시점 이후
          바로 삭제되며 복구할 수 없습니다.
          <ol class="sub">
            <li>발급 완료된 문서 및 각종 정보</li>
            <li>
              관련법령 및 회사의 개인정보 처리방침에서 정한 바에 따라 특별히 이용자와
              관계된 정보를 저장해야할 때
            </li>
          </ol>
        </li>
        <li>③ 회원은 탈퇴 시점 이후 동일한 이메일 주소로 다시 가입할 수 없습니다.</li>
      </ol>

      <h2>제15조 (이용제한 등)</h2>
      <ol>
        <li>
          ① 회사는 회원이 다음 각 호의 사유에 해당하는 경우, 회원자격을 제한 및
          정지시킬 수 있습니다.
          <ol class="sub">
            <li>가입 신청 시에 허위 내용을 등록한 경우</li>
            <li>
              다른 사람의 서비스 이용을 방해하거나 그 정보를 도용하는 등 전자상거래
              질서를 위협하는 경우
            </li>
            <li>
              서비스 이용에 관련하여 회원이 부담하는 채무를 기일에 지급하지 않는 경우
            </li>
            <li>
              서비스를 이용하여 법령 또는 이 약관이 금지하거나 공서양속에 반하는
              행위를 하는 경우
            </li>
          </ol>
        </li>
        <li>
          ② 회사가 제1항에 따라 회원 자격을 제한 및 정지시킨 후 동일한 행위가 2회 이상
          반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우 회사는 회원자격을
          상실시킬 수 있습니다.
        </li>
        <li>
          ③ 회사는 전1,2항의 규정에도 불구하고, 주민등록법을 위반한 명의도용 및
          결제도용, 저작권법 및 컴퓨터프로그램보호법을 위반한 불법프로그램의 제공 및
          운영방해, 정보통신망 이용촉진 및 정보보호 등에 관한 법률을 위반한 불법통신
          및 해킹, 악성프로그램의 배포, 접속권한 초과행위 등과 같이 관련법령을 위반한
          경우에는 즉시 계약을 해지 할 수 있습니다. 이에 따른 계약해지시 서비스 이용을
          통해 획득한 혜택 등은 모두 소멸되며, 회사는 이에 대해 별도로 보상하지
          않습니다.
        </li>
        <li>
          ④ 회사가 위 회원자격을 상실시키는 경우에는 회원 등록을 말소합니다. 이 경우
          회원에게 이를 통지하고, 회원등록 말소 전에 최소한 15일 기간을 정하여 소명할
          기회를 부여합니다.
        </li>
      </ol>

      <h2><제2장 서비스 이용 일반></h2>
      <h2>제16조(증서 발급 이용)</h2>
      <ol>
        <li>
          ① 발급기관이 증서 발급을 요청하는 시점에 발급 건당 이용권 1건이 차감됩니다.
        </li>
        <li>
          ② 발급기관의 증서 미발급, 발급 취소, 발급 거절발급 요청 취소 시 이용권은
          반환되지 않습니다.
        </li>
      </ol>

      <h2>제 17조 (회원의 게시물)</h2>
      <ol>
        <li>
          ① 회원이 서비스 이용 과정에서 등록 또는 게시한 증서 등 일체의 게시물 (이하
          게시물이라 함)에 대한 모든 책임은 회원에게 있으며, 회사는 회원의 게시물이
          다음 각 항에 해당한다고 판단되는 경우에 사전통지 없이 삭제할 수 있고, 이에
          대하여 회사는 어떠한 책임도 지지 않습니다.
          <ol class="sub">
            <li>타인을 모욕, 비방, 비하하거나 타인의 명예를 훼손하는 게시물</li>
            <li>범죄 및 불법 행위에 악용될 수 있는 정보를 담고 있는 게시물</li>
            <li>타인의 저작권, 상표권 등 권리를 침해하는 게시물</li>
            <li>
              음란물 또는 일반인의 성적 굴욕감이나 수치심을 유발할 수 있는 내용의
              게시물(그러한 내용이 담긴 웹사이트, 앱 등으로 연결될 수 있는 게시물을
              포함)
            </li>
            <li>
              어린이와 청소년의 정신적, 신체적 건강을 해칠 우려가 있는 선정적인 내용을
              담은 게시물
            </li>
            <li>특정인의 개인정보가 노출된 게시물</li>
            <li>
              특정 상품 등을 소개하여 판매하거나, 이를 구매, 사용하도록 권하거나
              연락을 유도하는 등 직간접적으로 영리추구를 위한 내용을 담고 있는 게시물
            </li>
            <li>
              서비스의 이용 목적에 부합하지 않거나 해당 서비스의 취지와 무관한 내용의
              게시물
            </li>
            <li>
              악성코드나 스파이웨어 등이 실행되어 다른 회원, 회사 기타 제3자의 시스템
              성능 저하, 개인정보 유출 등의 피해를 줄 수 있는 악의적인 코드를 담고
              있는 게시물
            </li>
            <li>정당한 사유 없이 회사의 영업을 방해하는 내용의 게시물</li>
            <li>
              기타 이 약관 또는 법령에 위배되거나 공서양속에 반하는 내용의 게시물
            </li>
          </ol>
        </li>
      </ol>

      <h2><제3장 유료서비스 이용></h2>
      <h2>제18조 (유료서비스 이용 계약)</h2>
      <ol>
        <li>
          ① 회사가 유료회원의 이용신청을 승낙한 때(일부 서비스의 경우, 이용 신청 접수
          후 사전 공지한 지정된 일자)로부터 유료서비스는 개시되며, 회사의 기술적 사유
          등 기타 사정에 의하여 유료서비스를 개시할 수 없는 경우에는 제9조의 방법에
          따라 회원에게 사전 공지합니다.
        </li>
        <li>
          ② 회사는 회원의 유료서비스 이용신청이 있게 되는 경우, 해당 회원에게
          이용신청의 내용을 통지합니다. 회사의 통지를 받은 회원은 의사표시의 불일치
          등이 있는 경우 회사에 정정 또는 수정을 요청하여야 하며, 회사는 회원의 요청에
          따라 처리하여야 합니다. 다만, 이미 대금을 지불한 경우에는 이 약관의 청약
          철회 등에 관한 규정에 따릅니다.
        </li>
        <li>
          ③ 회사는 회사의 사정, 기타 제반 여건에 따라 유료서비스 내용을 추가하거나
          변경할 수 있습니다.
        </li>
      </ol>

      <h2>제19조 (청약 철회 및 서비스 이용계약의 해제 • 해지)</h2>
      <ol>
        <li>
          ① 유료회원은 유료서비스 공급일 이후 제공되는 발급 이용 건을 사용하지 않았을
          경우 7일 이내에 회사에 결제 취소(청약 철회)를 요청할 수 있습니다.
        </li>
        <li>
          ② 유료회원이 유료서비스 공급일 이후 제공되는 발급 이용 건을 사용하지
          않았더라도 청약 철회가 가능한 기간(유료서비스 공급일로부터 7일 이내)을
          경과하여 청약 철회를 신청하면 환불은 불가능합니다.
        </li>
        <li>
          ③ 유료회원이 이미 결제한 서비스 이용 계약을 해제, 해지 했을 때, 남은 계약
          기간 동안 유료서비스를 이용할 수 있습니다.
        </li>
        <li>
          ④ 청약의 철회는 유료회원이 서비스 홈페이지를 통해 할 수 있으며, 회사에
          의사를 표시하여 회사에 도달될 때 그 효력이 발생하고, 회사는 유료회원의
          의사표시를 수령한 후 지체 없이 이러한 사실을 유료회원에게 회신합니다.
        </li>
        <li>
          ⑤ 회사는 유료회원이 청약 철회, 해지/해제 신청을 확인 후 환불 금액이 있을
          경우, 원칙적으로 유료회원의 해당 의사표시를 수령한 날로부터 3영업일 이내에
          결제수단 별 사업자에게 대금의 청구 정지 내지 취소를 요청하고, 유료회원이
          결제한 동일 결제수단으로 환불함을 원칙으로 합니다. 단, 회사가 사전에
          유료회원에게 전자메일, 서비스 홈페이지로 공지한 경우 및 아래의 각 호의
          경우와 같이 개별 결제 수단별 환불 방법, 환불 가능 기간 등이 차이가 있을 수
          있습니다.
          <ol class="sub">
            <li>
              신용카드 등 수납 확인이 필요한 결제수단의 경우에는 수납 확인일로부터
              3영업일 이내
            </li>
            <li>
              결제수단 별 사업자가 회사와의 약정을 통하여 사전에 대금 청구 정지 내지
              결제 취소 가능 기한 등을 미리 정하여 둔 경우로 해당 기한을 지난 환불의
              경우
            </li>
            <li>
              유료회원이 환불 처리에 필요한 정보 내지 자료를 회사에 즉시 제공하지 않는
              경우(현금 환불 시 신청인의 계좌 및 신분증 사본을 제출하지 아니하거나,
              타인 명의의 계좌를 제공하는 경우 등)
            </li>
            <li>해당 회원의 명시적 의사표시가 있는 경우</li>
          </ol>
        </li>
        <li>
          ⑥ 회사는 「콘텐츠산업 진흥법」제28조에 따라 제정된 「콘텐츠이용자보호지침」
          등에 따라, 회사가 부담하였거나 부담할 부대비용, 수수료를 차감하여 환불할 수
          있습니다.
        </li>
        <li>
          ⑦ 회원이 유료서비스를 선물 받거나, 프로모션 등을 통해 무상으로 취득하는 등
          회원이 직접 비용을 지불하지 아니하고 이용하는 유료서비스에 대하여 회사는
          회원에게 유료서비스 결제 대금을 환불할 의무를 부담하지 않습니다.
        </li>
        <li>
          ⑧ 회원이 이 약관에서 정하는 회원의 의무를 위반하였을 경우, 계약을 해제,
          해지하거나 서비스 이용 제한, 손해배상 청구 등의 조치를 취할 수 있으며, 계약
          해지 후 환불하여야 할 금액이 있을 경우에는 일정한 범위 내에서 회원이 부담할
          금액을 공제하고 환불합니다. 이 경우 회원은 해당 회사의 조치에 대하여 회사가
          정한 절차에 따라 이의 신청을 할 수 있으며, 회사는 정당하다고 확인하는 경우
          서비스 이용 재개 등을 할 수 있으며, 이용자가 자신의 고의, 과실이 없었음을
          입증한 경우 회사는 서비스를 정지한 기간만큼 이용 기간을 연장합니다.
        </li>
        <li>
          ⑨ 유료회원의 신청 또는 동의에 따라 월 정기결제 중인 유료서비스의 경우, 해당
          회원이 유료서비스의 이용요금을 체납하는 경우 연체가 발생한 날 회사로부터
          납입 요구 고지가 이루어진 이후에도 1주일 이내에 해당 회원이 납입하지 않는
          경우 회사에 의해 자동으로 이용권 해지가 될 수 있으므로, 유료서비스를
          유지하고자 하는 유료회원은 이용요금의 체납 또는 결제수단의 연체가 발생하지
          않도록 사전에 조치하여야 합니다.
        </li>
        <li>
          ⑩ 유료서비스를 이용 중인 유료회원이 탈퇴하는 경우 유료서비스는 즉시 해지되며
          환불은 불가능합니다. 이 경우 유료회원의 정보와 서비스이용내용은 관련 법령이
          정하는 경우를 제외하고는 약관 및 이 약관에 따라 처리됩니다.
        </li>
      </ol>

      <h2>제20조 (유료서비스 가액 변경)</h2>
      <p>
        회사는 운영상, 기술상의 필요에 따라 공급하는 유료서비스 가액을 변경할 수
        있으며, 제9조에서 정한 방법에 따라 유료회원에게 사전공지합니다.
      </p>

      <h2>제21조(과오금)</h2>
      <ol>
        <li>
          ① 회사는 유료서비스 결제와 관련하여 과오금이 발생한 경우 이용대금의 결제와
          동일한 방법으로 과오금 전액을 환불합니다. 다만, 동일한 방법으로 환불이
          불가능할 때는 이를 사전에 고지합니다.
        </li>
        <li>
          ② 회사의 귀책사유로 과오금이 발생한 경우 과오금 전액을 환불합니다. 단,
          회원의 귀책사유로 과오금이 발생한 경우, 회사가 과오금을 환불하는데 소요되는
          비용은 합리적인 범위 내에서 이용자가 부담하여야 하며, 회사는 해당 비용을
          차감 후 과오금을 환불할 수 있습니다.
        </li>
        <li>
          ③ 회사는 회원이 과오금을 주장하며 환불을 요구하는 경우, 정당하게 유료서비스
          요금이 부과되었음을 입증하면 환불을 거부할 수 있습니다.
        </li>
        <li>
          ④ 회사는 과오금의 세부 환불절차 및 기타 사항에 대하여 다음과 같이
          「콘텐츠이용자보호지침」이 정하는 바에 따릅니다.
          <ol class="sub">
            <li>
              회사나 회원이 과오금의 발생사실을 안 때에는 이메일, 서비스 홈페이지 등
              회사가 제시한 방법에 따라 상대방에게 통보
            </li>
            <li>
              회사는 회원에게 환불에 필요한 정보를 요청(회원 성명, 결제증빙서류,
              전화번호, 환불 요청 계좌 등)
            </li>
            <li>회원은 환불에 필요한 2호의 정보를 회사에 제공</li>
            <li>
              회사는 이용자의 정보 제공일로부터 7일 이내 환불 처리 (회원의 명시적
              의사표시가 있는 경우 차회 요금에서 상계)
            </li>
          </ol>
        </li>
      </ol>

      <h2><제4장 기타></h2>
      <h2>제22조 (최소 사용 환경)</h2>
      <ol>
        <li>
          ① 이용자는 아래 각 호에 해당하는 최소 사용 환경에서 서비스를 이용해야
          합니다.
          <ol class="sub">
            <li>운영체제 : Mac(Mavericks 이상), Windows7 이상</li>
            <li>
              브라우저 : Internet Explorer(11 이상), Edge(25.1 이상), Chrome(49 이상),
              Safari(9.0 이상) Opera(37 이상), Firefox(43.0 이상)
            </li>
            <li>쿠키 : 모든 쿠키 허용</li>
          </ol>
        </li>
        <li>
          ② 최소 사용 환경을 충족하지 못하는 환경에서 서비스를 이용할 경우, 이와
          발생하는 문제에 대해 회사는 책임지지 않습니다.
        </li>
      </ol>

      <h2>제23조 (회사의 의무)</h2>
      <ol>
        <li>
          ① 회사는 본 약관 및 관련법령에서 금지하는 행위 및 미풍양속에 반하는 행위를
          하지 않으며, 계속적이고 안정적인 서비스의 제공을 위하여 최선을 다하여
          노력합니다.
        </li>
        <li>
          ② 회사는 이용자가 안전하게 서비스를 이용할 수 있도록 신용정보를 포함한
          일체의 개인정보 보호를 위한 보안시스템을 갖추어야 하며 개인정보처리방침을
          공시하고 준수합니다.
        </li>
        <li>
          ③ 회사는 이용자로부터 제기되는 의견이나 불만이 정당하다고 객관적으로 인정될
          경우에는 합리적인 기간 내에 신속하게 처리하여야 합니다. 다만, 처리에
          장기간이 소요되는 경우 이용자에게 게시판 또는 이메일 등을 통하여 지체 사유를
          안내하고 처리과정 및 처리결과를 전달합니다.
        </li>
        <li>
          ④ 회사는 이용계약의 체결, 계약사항의 변경 및 해지 등 이용자와의 계약관련
          절차 및 내용 등에 있어 이용자에게 편의를 제공하도록 노력합니다.
        </li>
      </ol>

      <h2>제24조 (이용자의 의무)</h2>
      <ol>
        <li>
          ① 회원은 회사에서 제공하는 서비스를 본래의 이용 목적 이외의 용도로
          사용하거나 다음 각 호에 해당하는 행위를 해서는 안됩니다.
          <ol class="sub">
            <li>
              가입신청 또는 정보 변경을 목적으로 회사에 개인정보 등록시 실명이 아닌
              정보 또는 다른 사람의 정보를 사용하거나 허위 사실을 기재하는 행위
            </li>
            <li>
              타인으로 가장하거나 타인과의 관계를 허위로 명시하는 행위, 다른 회원의
              계정 및 비밀번호를 도용, 부정하게 사용하는 행위
            </li>
            <li>알려지거나 알려지지 않은 버그를 악용하여 서비스를 이용하는 행위</li>
            <li>
              회사 및 제3자의 명예를 훼손하거나 업무를 방해하거나 회사 및 제3자에게
              손해를 가하는 행위
            </li>
            <li>
              회사의 지적재산권, 제3자의 지적재산권, 초상권 등 기타 권리를 침해하거나
              회사의 승인을 받지 않고 다른 회원의 개인정보를 수집, 저장, 유포,
              게시하는 행위
            </li>
            <li>
              제3자를 기망하여 이득을 취하거나 회사가 제공하는 서비스를 불건전하게
              이용하거나 하여 제3자에게 피해를 주는 행위
            </li>
            <li>
              회사로부터 특별한 권리를 부여받지 않고 사이트를 변경하거나 사이트에 다른
              프로그램을 추가 또는 삽입하거나 서버를 해킹, 역설계, 소스코드의 유출 및
              변경, 별도의 서버를 구축하거나 웹사이트의 일부분을 임의로 변경 또는
              도용하여 회사를 사칭하는 행위
            </li>
            <li>
              회사의 직원이나 운영자를 가장, 사칭하거나 또는 타인의 명의를 도용하여
              문서를 등록하거나 메일을 발송하는 행위
            </li>
            <li>
              회사의 동의 없이 영리, 영업, 광고, 정치활동, 불법선거운동 등을 목적으로
              서비스를 이용하는 행위
            </li>
            <li>
              기타 공공질서 및 미풍양속을 위반하거나 불법적, 부당한 행위 및 법령에
              위배되는 행위
            </li>
          </ol>
        </li>
        <li>
          이용자는 회사 홈페이지 상의 공지사항 및 이용약관의 수정사항 등을 확인하고
          이를 준수할 의무가 있으며 기타 회사의 업무에 방해되는 행위를 하여서는 안
          됩니다.
        </li>
        <li>
          이용자의 계정에 관한 관리 책임은 이용자에게 있으며, 이를 제3자가 이용하도록
          하여서는 안 됩니다. 회원은 자신의 계정이 도난당하거나 제3자가 사용하고
          있음을 인지한 경우에는 바로 회사에 통보하고 회사의 안내가 있는 경우에는 그에
          따라야 합니다.
        </li>
        <li>
          회사는 제1항, 제2항 및 다음 각 호의 어느 하나에 해당하는 행위의 구체적인
          유형을 운영 정책에서 정할 수 있으며, 이용자는 이를 준수할 의무가 있습니다.
          <ol class="sub">
            <li>이용자의 계정명, 비밀번호의 정함에 대한 제한</li>
            <li>
              기타 이용자의 서비스 이용에 대한 본질적인 권리를 침해하지 않는 범위
              내에서 회사가 운영상 필요하다고 인정되는 사항
            </li>
          </ol>
        </li>
      </ol>

      <h2>제25조 (손해배상)</h2>
      <ol>
        <li>
          ① 회사가 제공하는 유료서비스의 하자 등에 의하여 이용자에게 피해가 발생한
          경우, 이용자에 대한 피해보상의 기준, 범위, 방법 및 절차에 관한 사항은
          디지털콘텐츠 이용자보호지침에 따릅니다.
        </li>
        <li>
          ② 이용자가 본 약관의 의무를 위반함으로 인하여 회사에 손해를 입힌 경우 또는
          이용자가 서비스의 이용과 관련하여 회사에 손해를 입힌 경우 이용자는 회사에
          대하여 손해를 배상하여야 합니다.
        </li>
        <li>
          ③ 이용자가 서비스를 이용함에 있어 행한 불법행위 또는 본 약관을 위반한 행위로
          회사가 당해 이용자 외의 제3자로부터 손해배상청구 또는 소송 등 각종
          이의제기를 받는 경우 당해 이용자는 자신의 책임과 비용으로 회사를 면책시켜야
          하며, 회사가 면책되지 못한 경우 당해 이용자는 그로 인하여 회사에 발생한 모든
          손해를 배상할 책임이 있습니다.
        </li>
      </ol>

      <h2>제26조 (책임의 한계)</h2>
      <ol>
        <li>
          ① 회사는 천재지변 또는 국가 정책 등 이에 준하는 불가항력으로 인하여 서비스를
          제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.
        </li>
        <li>
          ② 회사는 이용자의 귀책사유로 인한 서비스 중지 또는 이용의 장애,
          기간통신사업자가 전기통신서비스를 중지하거나 정상적으로 제공하지 아니하여
          이용자에게 손해가 발생한 경우에는 책임이 면제됩니다.
        </li>
        <li>
          ③ 회사는 이용자가 서비스와 관련하여 게재한 정보, 자료, 사실의 신뢰도, 정확성
          등의 내용에 관하여는 책임을 지지 않습니다.
        </li>
        <li>
          ④ 회사는 이용자 간 또는 이용자와 제3자 상호간에 서비스를 매개로 하여 거래
          등을 한 경우에는 책임이 면제됩니다.
        </li>
        <li>
          ⑤ 이용자는 무료로 제공되는 서비스 이용과 관련하여 관련법령에 특별한 규정이
          없는 한 책임을 지지 않습니다.
        </li>
      </ol>

      <h2>제 27조 (연결서비스와 피연결서비스간의 관계)</h2>
      <ol>
        <li>
          ① 회사의 서비스와 타 회사의 서비스가 하이퍼링크 방식 등으로 연결된 경우,
          전자를 연결서비스라 하고 후자를 피연결서비스라 합니다.
        </li>
        <li>
          ② 연결서비스는 피연결서비스가 독자적으로 제공하는 서비스에 의하여 회원과
          행하는 거래에 대해서 보증 책임을 지지 않습니다.
        </li>
      </ol>

      <h2>제28조 (준거법 및 재판관할)</h2>
      <ol>
        <li>① 회사와 이용자 간 제기된 소송은 대한민국법을 준거법으로 합니다.</li>
        <li>
          ② 회사와 이용자 간 발생한 분쟁에 관한 소송은 민사소송법 상의 관할법원에
          제소합니다.
        </li>
      </ol>
      <h1 id="privacy-policy">broof 개인정보 수집 및 이용 동의</h1>

      <p>아이콘루프(이하 ‘회사’)는 개인정보를 아래와 같이 수집 및 이용합니다.</p>

      <table>
        <tbody>
          <tr>
            <td style="width: 22%;"><strong>목적</strong></td>
            <td style="width: 56%"><strong>수집 항목</strong></td>
            <td style="width: 22%"><strong>보유 기간</strong></td>
          </tr>
          <tr>
            <td style="width: 22%;">기관 등록</td>
            <td style="width: 56%;">(필수) 이메일주소, 기관명(회사명)</td>
            <td style="width: 22%;">회원 탈퇴시까지</td>
          </tr>
          <tr>
            <td style="width: 22%;">증명서 조회 및 발급</td>
            <td style="width: 56%;">(필수) 이름, 이메일주소, 생년월일, 휴대폰번호, CI, 인증서 정보 등</td>
            <td style="width: 22%;">회원 탈퇴시까지</td>
          </tr>
        </tbody>
      </table>

      <ol>
        <li>* 증명서 종류에 따라 수집하는 내용이 상이할 수 있습니다.</li>
        <li>* 수령자(개인)의 경우, 각 기관에서 발급받은 본인의 인증서 및 개인정보를 자체 관리할 수 있습니다.</li>
        <li>* 서비스 제공을 위한 최소한의 개인정보이므로 동의를 해주셔야 서비스 이용이 가능합니다.</li>
      </ol>

      <br />
      <p class="version">v1.1.0</p>

     
    `,
  },
];

export default tosData;
