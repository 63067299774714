import React, { useEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import {
  NoticePage,
  FaqPage,
  ContactPage,
  TosPage,
  TosDetailPage,
  GuidePage,
  GuideDetailPage,
  OssPage,
} from "./pages";
import { createUseStyles } from "react-jss";
import { ThemeType } from "./styles/theme";

const useStyles = createUseStyles((theme: ThemeType) => ({
  "@global": {
    html: {
      fontSize: "62.5%",
      fontFamily: theme.font,
      letterSpacing: "-0.05rem",
      color: theme.mono0,
      fontWeight: theme.demiLight,
      lineHeight: "2.6rem",
      background: theme.mono100,
    },
    body: {
      fontSize: "1.6rem",
      "-webkit-font-smoothing": "antialiased",
      "-moz-osx-font-smoothing": "grayscale",
      lineHeight: "2.6rem",
      background: theme.mono100,
    },
    ".container": {
      width: "100%",
      margin: "0 auto",
      padding: `30px ${theme.gap}px 0px`,
      background: theme.mono100,
    },
    a: {
      fontSize: "1.4rem",
      color: theme.primaryTxt,
      textDecoration: "none",
      outline: "none",
      "&:hover": {
        textDecoration: "none",
        color: theme.primaryTxt,
      },
      "&:active": {
        textDecoration: "none",
        color: theme.primaryTxt,
      },
    },
  },
}));

function Routes() {
  useStyles();

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div>
      <Switch>
        <Route exact path="/notice" component={NoticePage} />
        <Route exact path="/tos" component={TosPage} />
        <Route path="/tos/:tosId" component={TosDetailPage} />
        <Route exact path="/guide" component={GuidePage} />
        <Route path="/guide/:guideId" component={GuideDetailPage} />
        <Route exact path="/contact" component={ContactPage} />
        <Route exact path="/faq" component={FaqPage} />
        <Route exact path="/oss" component={OssPage} />
      </Switch>
    </div>
  );
}

export default Routes;
