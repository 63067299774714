import React, { useCallback } from "react";
// @ts-ignore
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import { createUseStyles } from "react-jss";
import { ThemeType } from "../styles/theme";
import { useListStyles } from "../components/";
import tosData from "../constants/TosData";
import clsx from "clsx";

const useStyles = createUseStyles((theme: ThemeType) => ({
  wrap: {
    width: "100%",
  },
  text: {
    fontSize: "1.8rem",
    padding: "23px 0px",
  },
  info: {
    fontSize: "1.4rem",
    fontWeight: theme.demiLight,
    padding: "23px 20px",
    lineHeight: "2.4rem",
    position: "fixed",
    bottom: 0,
  },
  bold: {
    fontWeight: theme.medium,
    marginBottom: 12,
  },
}));

const TosPage: React.FC = () => {
  const classes = useStyles();
  const listClasses = useListStyles({});
  const history = useHistory();

  const goToDetailPage = useCallback(
    (i: number) => {
      history.push(`/tos/${i}`);
    },
    [history]
  );

  return (
    <>
      <Helmet>
        <title>쯩 - 이용약관</title>
      </Helmet>
      <div className={classes.wrap}>
        <ul className={listClasses.list}>
          {tosData.map((item, i) => {
            return (
              <li
                key={i}
                onClick={() => goToDetailPage(i)}
                className={listClasses.item}
              >
                <div
                  className={clsx(listClasses.banner, classes.text, "right")}
                >
                  {item.title}
                </div>
              </li>
            );
          })}
        </ul>
        <div className={classes.info}>
          <p className={classes.bold}>주식회사 아이콘루프</p>
          <p>
            대표 김종협
            <br />
            서울시 중구 청계천로 100, 시그니쳐타워 3층
            <br />
            사업자 등록번호 647-81-00375
            <br />
            대표전화 02-2138-7026
          </p>
        </div>
      </div>
    </>
  );
};

export default TosPage;
