import React from "react";
import { createUseStyles } from "react-jss";
import { ThemeType } from "../../styles/theme";

interface Props {
  text: string;
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
  disabled: boolean;
}

const useStyles = createUseStyles((theme: ThemeType) => ({
  button: {
    width: "100%",
    fontSize: "2.0rem",
    background: theme.primarySol,
    height: 60,
    color: theme.mono100,
    padding: "15px 20px",
    fontWeight: theme.regular,
    "&:disabled": {
      background: theme.monoDisabled,
      color: theme.mono50,
    },
  },
}));

const Button: React.FC<Props> = ({ text, onClick, disabled }) => {
  const classes = useStyles();

  return (
    <button disabled={disabled} onClick={onClick} className={classes.button}>
      {text}
    </button>
  );
};

export default Button;
