import React from "react";
import { createUseStyles } from "react-jss";
import { ThemeType } from "../../styles/theme";

interface Props {
  text: string;
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
}

const useStyles = createUseStyles((theme: ThemeType) => ({
  button: {
    fontSize: "1.4rem",
    color: theme.primaryTxt,
    margin: "0 auto",
    display: "block",
    borderBottom: `1px solid ${theme.primaryTxt}`,
    marginBottom: 60,
  },
}));

const FlatButton: React.FC<Props> = ({ text, onClick }) => {
  const classes = useStyles();

  return (
    <button onClick={onClick} className={classes.button}>
      {text}
    </button>
  );
};

export default FlatButton;
