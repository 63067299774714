import React from "react";
import AwesomeSlider from "react-awesome-slider";
import "react-awesome-slider/dist/styles.css";
import "../../styles/slider.css";

interface Props {
  images: string[];
  alt: string;
  isCaptionExist?: boolean;
  captions?: string[];
}

const Slider: React.FC<Props> = (props) => {
  const { images, alt } = props;

  return (
    <AwesomeSlider
      media={images.map((image, i) => {
        return {
          source: image,
          alt: alt,
        };
      })}
    />
  );
};

export default Slider;
