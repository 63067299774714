const theme = {
  // Color
  accent: "rgb(57, 220, 206)",
  primarySol: "rgb(77, 135, 255)",
  primaryTxt: "rgb(39, 109, 255)",
  mono0: "rgb(34, 34, 34)",
  monoDivider: "rgb(225, 225, 225)",
  monoDisabled: "rgb(228, 228, 228)",
  mono50: "rgb(138, 138, 138)",
  monoBg: "rgb(246, 246, 246)",
  mono100: "#FFF",
  success: "rgb(57, 220, 156)",
  danger: "rgb(247, 68, 68)",

  // Font
  font: '"Noto Sans KR", sans-serif',
  demiLight: 300,
  regular: 400,
  medium: 500,
  bold: 700,

  // Layout
  gap: 20,
};

export default theme;
export type ThemeType = typeof theme;
