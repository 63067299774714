import React, { useRef, RefObject, useState, useCallback } from "react";
import { createUseStyles } from "react-jss";
import { ThemeType } from "../../styles/theme";
import useOutsideClick from "../../hooks/useOutsideClick";
import clsx from "clsx";

interface Props {
  title: string;
  selectedIdx: number;
  select: (idx: number) => void;
  toggleSelector: () => void;
  data: string[];
}

const useStyles = createUseStyles((theme: ThemeType) => ({
  "@keyframes slideup": {
    "0%": {
      transform: "translateY(600px)",
    },
    "100%": {
      transform: "translateY(0px)",
    },
  },
  "@keyframes slidedown": {
    "0%": {
      transform: "translateY(0px)",
    },
    "100%": {
      transform: "translateY(600px)",
    },
  },
  "@keyframes fadein": {
    "0%": {
      opacity: 0,
    },
    "100%": {
      opacity: 1,
    },
  },
  "@keyframes fadeout": {
    "0%": {
      opacity: 1,
    },
    "100%": {
      opacity: 0,
    },
  },
  list: {
    "overflow-y": "auto",
    height: 296,
  },
  selector: {
    position: "fixed",
    width: "100%",
    top: 0,
    left: 0,
    "& > .dim": {
      animation: "$fadein 0.25s ease",
      height: "100vh",
      background: "rgba(0, 0, 0, 0.3)",
      display: "flex",
      alignItems: "flex-end",
      flexDirection: "column",
      justifyContent: "flex-end",
      "&.close": {
        animation: "$fadeout 0.25s ease",
      },
      "& > div": {
        animation: "$slideup 0.25s ease",
        width: "100%",
        background: theme.mono100,
        borderRadius: "10px 10px 0px 0px",
        padding: `22px ${theme.gap}px 0px`,
        "&.close": {
          animation: "$slidedown 0.25s ease",
        },
        "& p": {
          color: theme.mono0,
          fontSize: "1.8rem",
          marginBottom: 18,
        },
        "& ul": {
          listStyle: "none",
          padding: 0,
          margin: 0,
          "& li": {
            margin: "0 auto",
            color: theme.mono50,
            fontSize: "1.8rem",
            padding: "23px 0",
            borderBottom: `1px solid ${theme.monoDivider}`,
            "&:last-of-type": {
              borderBottom: "none",
            },
            "&.selected": {
              color: theme.primaryTxt,
            },
          },
        },
      },
    },
  },
}));

// function enableScroll() {
//   document.body.style.overflow = "auto";
// }

// function disableScroll() {
//   document.body.style.overflow = "hidden";
// }

const Selector: React.FC<Props> = (props) => {
  const classes = useStyles(props);
  const { toggleSelector, selectedIdx, select, data, title } = props;
  const [isClosing, close] = useState<boolean>(false);
  const ref = useRef<HTMLUListElement>();

  // useEffect(() => {
  //   disableScroll();
  //   return () => {
  //     enableScroll();
  //   };
  // }, []);

  const closeSelector = useCallback(() => {
    close(true);
    setTimeout(() => {
      toggleSelector();
    }, 240);
  }, [toggleSelector]);

  const handleSubmit = useCallback(
    (idx: number) => {
      select(idx);
      closeSelector();
    },
    [closeSelector, select]
  );

  useOutsideClick(ref, () => {
    closeSelector();
  });

  return (
    <div className={classes.selector}>
      <div className={clsx("dim", isClosing && "close")}>
        <div className={clsx(isClosing && "close")}>
          <p>{title}</p>
          <ul className={classes.list} ref={ref as RefObject<HTMLUListElement>}>
            {data.map((item, i) => {
              return (
                <li
                  key={i}
                  data-value={i}
                  onClick={() => handleSubmit(i)}
                  className={clsx(selectedIdx === i && "selected")}
                >
                  {item}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Selector;
