import { Data } from "../types";

const faqData: Data[] = [
  {
    title: "휴대폰 인증, 증명서 유효기간이 있나요?",
    desc: `
      <p>발급받은 휴대폰 인증의 유효기간은 발급일로부터 2년이며, 증명서는 따로 유효기간이 정해져 있지 않습니다.</p>
    `,
  },
  {
    title: "휴대폰을 분실하면 어떻게 해야 하나요?",
    desc: `
    <p>휴대폰을 분실해도 내 비밀번호 또는 생체 정보가 노출되지 않는 한, 개인 정보 유출의 위험은 없습니다. 앱을 재설치하면 서비스를 다시 이용할 수 있습니다.</p>
    `,
  },
  {
    title:
      "휴대폰 기기 변경 시 기존에 발급받은 휴대폰 인증과 증명서는 어떻게 되나요?",
    desc: `
      <p>보안상의 이유로 휴대폰 기기 변경 시 기존에 발급 받은 휴대폰 인증과 증명서는 더 이상 사용이 불가하며 재발급이 필요합니다.</p>
    `,
  },
  {
    title: "휴대폰 번호가 바뀌었는데 어떻게 하면 되나요?",
    desc: `
    <p>휴대폰 번호가 바뀐 경우, 휴대폰 인증을 재발급 받아 새 휴대폰 번호로 변경할 수 있습니다.<br />휴대폰 인증 상세 화면의 ‘재발급' 버튼을 눌러 진행할 수 있습니다.</p>
    `,
  },
  {
    title: "broof가 무엇인가요?",
    desc: `
    <p>broof란 발급 받은 증명서를 한 곳에서 안전하게 관리하고 필요한 곳에 제출할 수 있는 블록체인 기반 디지털 증명서 서비스입니다.</p>
    `,
  },
  {
    title: "broof 연동이 무엇인가요?",
    desc: `
    <p>broof에 보관된 증명서들을 쯩에 불러옴으로써 발급 받은 증명서들을 쯩에서 더욱 안전하고 편리하게 관리할 수 있습니다.</p>
    `,
  },
  {
    title: "증명서를 불러오려면 어떻게 해야하나요?",
    desc: `
    <p>broof에 회원가입을 해야 하며 기존에 발급 받은 broof 증명서가 있는 경우 쯩으로 불러올 수 있습니다.</p>
    `,
  },
  {
    title: "증명서를 삭제할 수 있나요?",
    desc: `
    <p>사용자가 직접 등록한 증명서와 폐기된 증명서는 해당 증명서 상세 화면의 '삭제' 버튼을 눌러 삭제할 수 있습니다. 단, 발급 기관에서 발급한 증명서는 해당 기관만이 폐기할 수 있으며 삭제된 증명서는 복구할 수 없으니 유의해주세요.</p>
    `,
  },
  {
    title: "비밀번호를 변경하고 싶어요.",
    desc: `
    <p>비밀번호는 ‘설정>인증 관리>비밀번호 변경'에서 변경할 수 있습니다.<br />비밀번호는 분실하면 다시 찾을 수 없으니 신중하게 설정해주세요.</p>
    `,
  },
  {
    title: "비밀번호가 생각이 안나요.",
    desc: `
    <p>쯩은 비밀번호를 포함한 사용자 정보를 따로 저장하지 않습니다. 따라서 비밀번호 분실 시 되찾을 수 없으며, 서비스를 초기화하여 사용해야 합니다. 앱을 삭제 후 재설치하면 서비스 초기화가 완료됩니다.</p>
    `,
  },
  {
    title: "생체 인증이 등록되지 않아요.",
    desc: `
    <p>휴대폰 설정에서 생체 인증 사용이 설정되었는지 확인해주세요.</p>
    <p class="gray">[방법]<br/>
    1) iOS : 설정 > Face ID(또는 Touch ID) 및 암호<br/>
    2) 안드로이드 : 설정 > 생체 인증 및 보안</p>
    `,
  },
  {
    title: "서비스를 그만 사용하고 싶은데, 내 개인정보가 남아있지 않나요?",
    desc: `
    <p>앱을 지우면 발급받은 휴대폰 인증과 증명서가 모두 삭제됩니다. 또한, 사용 시 등록한 휴대폰 인증 및 증명서 정보는 사용자 기기 이외에 따로 저장되지 않습니다.</p>
    `,
  },
];

export default faqData;
