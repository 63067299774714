export const closeWebView = () => {
  try {
    //@ts-ignore
    if (CloseWebView !== "undefined") {
      //@ts-ignore
      CloseWebView.postMessage(true);
    }
  } catch (e) {
    console.error(e);
  }
};

export const openWebView = (id: number) => {
  try {
    //@ts-ignore
    if (OpenWebView !== "undefined") {
      //@ts-ignore
      OpenWebView.postMessage(`${id + 1}`);
    }
  } catch (e) {
    console.error(e);
  }
};

export const showKeyboard = () => {
  try {
    //@ts-ignore
    if (ShowKeyboard !== "undefined") {
      //@ts-ignore
      ShowKeyboard.postMessage(true);
    }
  } catch (e) {
    console.error(e);
  }
};

export const dismissKeyboard = () => {
  try {
    //@ts-ignore
    if (DismissKeyboard !== "undefined") {
      //@ts-ignore
      DismissKeyboard.postMessage(true);
    }
  } catch (e) {
    console.error(e);
  }
};

export const launch = () => {
  try {
    //@ts-ignore
    if (Launch !== "undefined") {
      //@ts-ignore
      Launch.postMessage("https://zzeung.id/#partners");
    }
  } catch (e) {
    console.error(e);
  }
};
