import React, { useCallback } from "react";
import AwesomeSlider from "react-awesome-slider";
import { Caption } from "../";
import { GuideData } from "../../types";
import "react-awesome-slider/dist/styles.css";
import "../../styles/slider.css";
import { openWebView } from "../../apis/javascriptChannel";

interface Props {
  data: GuideData[];
}

const SliderWithCaption: React.FC<Props> = (props) => {
  const { data } = props;

  const handleClick = useCallback((id: number) => {
    openWebView(id);
  }, []);

  return (
    <AwesomeSlider>
      {data.map((item: GuideData, i: number) => (
        <div
          style={{
            backgroundColor: item.keyColor,
          }}
          key={i}
          data-src={item.img}
          data-alt={item.title}
          onClick={() => handleClick(i)}
        >
          <Caption item={item} isGuideDetailPage={false} />
        </div>
      ))}
    </AwesomeSlider>
  );
};

export default SliderWithCaption;
