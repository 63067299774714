import { NoticeData } from "../types";

const noticeData: NoticeData[] = [
  {
    title: "쯩 서비스를 오픈했어요.",
    desc: `
      <p>나를 증명하기 위해 매 번 겪였던 불편함!<br />쯩이 해결해드립니다.<br />자기 증명 서비스 시작의 첫 걸음에 함께 해주세요.<br />앞으로 더욱 다양한 MyID와 증명서 서비스를 추가할 예정이니 기대해주세요! (꾸벅)</p><p>@쯩 서비스팀 드림</p> 
    `,
    createdAt: "2020.06.04",
  },
];

export default noticeData;
