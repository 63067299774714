import React, { useCallback, useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
// @ts-ignore
import { Helmet } from "react-helmet";
import { createUseStyles } from "react-jss";
import useScroll from "../hooks/useScroll";
import { Header, Caption } from "../components";
import { ThemeType } from "../styles/theme";
import { guideData } from "../constants/GuideData";
import { GuideData } from "../types";
import Divider from "../assets/guide/divider@2x.png";

interface ParamTypes {
  guideId: string;
}

interface StyleProps {
  item: GuideData;
}

const useStyles = createUseStyles((theme: ThemeType) => ({
  wrap: {
    width: "100%",
    margin: "0 auto",
  },
  top: {
    position: "relative",
    height: 420,
    color: theme.mono100,
    background: ({ item }: StyleProps) =>
      `url(${item.img}) bottom center no-repeat`,
    backgroundSize: "100% auto!important",
    "@media (max-width: 375px)": {
      backgroundSize: "375px auto!important",
    },
  },
  desc: {
    padding: `30px 0px`,
    "& > h2:first-child": {
      padding: `0px ${theme.gap}px`,
      marginTop: 0,
    },
    "& h2": {
      padding: `0px ${theme.gap}px`,
      fontSize: "2.2rem",
      marginTop: 60,
      fontWeight: theme.demiLight,
      lineHeight: "3.2rem",
    },
    "& > p:first-child": {
      marginTop: 0,
    },
    "& p": {
      padding: `0px ${theme.gap}px`,
      fontSize: "1.8rem",
      color: theme.mono50,
      marginTop: 20,
      lineHeight: "3.2rem",

      "& span": {
        display: "block",
        marginTop: 30,
        "&:first-of-type": {
          marginTop: 0,
        },
      },
    },
    "& img": {
      padding: `0px ${theme.gap}px`,
      width: "100%",
      marginTop: 20,
      "&.banner": {
        padding: 0,
      },
    },
    "& div.slider": {
      padding: `0px ${theme.gap}px`,
      marginTop: 20,
      "& ~ h2": {
        marginTop: 86,
      },
    },
    "& div.divider": {
      padding: `0px ${theme.gap}px`,
      width: "100%",
      background: `url(${Divider}) center center no-repeat`,
      backgroundSize: "143px 20px",
      height: 20,
      marginTop: 60,
    },
  },
  button: {
    display: "block",
    margin: "30px auto 40px",
    width: `calc(100% - ${theme.gap * 2}px)`,
    borderRadius: 6,
    border: `1px solid ${theme.primarySol}`,
    padding: `13px ${theme.gap}px`,
    color: theme.primaryTxt,
    fontSize: "1.8rem",
    lineHeight: "2.8rem",
  },
}));

const GuideDetailPage: React.FC = () => {
  const history = useHistory();
  const { guideId } = useParams<ParamTypes>();
  const item = guideData[Number(guideId) - 1];
  const classes = useStyles({ item });

  const { scrollY } = useScroll();
  const [isShown, setShown] = useState<boolean>(false);

  useEffect(() => {
    if (scrollY > 356 && document.body.getBoundingClientRect().top < 0) {
      setShown(true);
    } else {
      setShown(false);
    }
  }, [scrollY]);

  const handleButtonClick = useCallback(() => {
    history.push(`/guide${item.buttonLink}`);
    setShown(false);
  }, [history, item.buttonLink]);

  return (
    <div className={classes.wrap}>
      <Helmet>
        <title>쯩 - {item.title}</title>
      </Helmet>
      <Header isShown={isShown} title={item.title} />
      <section className={classes.top}>
        <Caption
          item={{
            title: item.title,
            subTitle: item.subTitle,
          }}
          isGuideDetailPage={true}
        />
      </section>
      <div className={classes.desc}>{item.desc}</div>
      <button className={classes.button} onClick={handleButtonClick}>
        {item.buttonText}
      </button>
    </div>
  );
};

export default GuideDetailPage;
