export const CATEGORY_LIST = [
  "사용자 불편 문의",
  "개인 정보 보호 문의",
  "아이디어 제안",
  "기타",
];

export const EMAIL_DOMAIN_LIST = [
  "직접 입력",
  "naver.com",
  "gmail.com",
  "daum.net",
  "hanmail.net",
  "nate.com",
  "hotmail.com",
  "icloud.com",
];
