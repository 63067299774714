import React from "react";
// @ts-ignore
import { Helmet } from "react-helmet";
import { createUseStyles } from "react-jss";
import { ThemeType } from "../styles/theme";
import { FaqItem, List } from "../components/";
import faqData from "../constants/FaqData";

const useStyles = createUseStyles((theme: ThemeType) => ({
  wrap: {
    width: "100%",
  },
}));

const FaqPage: React.FC = () => {
  const classes = useStyles();

  return (
    <>
      <Helmet>
        <title>쯩 - FAQ</title>
      </Helmet>
      <div className={classes.wrap}>
        <List data={faqData} itemComponent={FaqItem} />
      </div>
    </>
  );
};

export default FaqPage;
