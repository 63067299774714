import React, { useState, useEffect, useCallback } from "react";
import { createUseStyles } from "react-jss";
import { ThemeType } from "../../styles/theme";
import { FlatButton } from "../";
import downArrow from "../../assets/downArrow@2x.png";
import upArrow from "../../assets/upArrow@2x.png";
import rightArrow from "../../assets/rightArrow@2x.png";
import clsx from "clsx";

interface Props {
  data: any[];
  itemComponent: any;
  isGuidePage?: boolean;
}

export const useListStyles = createUseStyles((theme: ThemeType) => ({
  wrap: {
    width: "100%",
  },
  list: {
    listStyle: "none",
    padding: 0,
    marginBottom: "27px!important",
  },
  item: {
    borderBottom: (props: Props) =>
      props.isGuidePage ? "none" : `1px solid ${theme.monoDivider}`,
  },
  banner: {
    padding: "21px 0 23px 0",
    width: `calc(100% - ${theme.gap * 2}px)`,
    margin: "0 auto",
    background: `url(${downArrow}) no-repeat`,
    backgroundSize: "20px 20px",
    backgroundPosition: "right 0px top 25px",
    "&.open": {
      background: `url(${upArrow}) no-repeat`,
      backgroundSize: "20px 20px",
      backgroundPosition: "right 0px top 25px",
    },
    "&.right": {
      background: `url(${rightArrow}) no-repeat`,
      backgroundSize: "40px 40px",
      backgroundPosition: "right 0px top 15px",
    },
  },
  desc: {
    display: "none",
    width: "100%",
    background: theme.monoBg,
    padding: `24px ${theme.gap}px 30px`,
    "&.open": {
      display: "block",
    },
    "& p": {
      marginBottom: 24,
      lineHeight: "2.6rem",
      "&:last-of-type": {
        marginBottom: 0,
      },
      "&.gray": {
        color: theme.mono50,
      },
    },
  },
}));

const List: React.FC<Props> = (props) => {
  const classes = useListStyles(props);
  const [_data, _setData] = useState<any[]>([]);
  const [isExpanded, setExpanded] = useState<boolean>(false);
  const [selectedIdx, setIdx] = useState<number>(-1);
  const { data, itemComponent, isGuidePage = false } = props;

  useEffect(() => {
    if (isGuidePage) {
      _setData(data.slice(0, 3));
    } else {
      _setData(data);
    }
  }, [data, isGuidePage]);

  const toggleNotice = useCallback(
    (idx: number) => {
      setIdx(selectedIdx !== idx ? idx : -1);
    },
    [selectedIdx]
  );

  const showAllItems = useCallback(() => {
    setExpanded(true);
    _setData(data);
  }, [data]);

  const hideItems = useCallback(() => {
    setExpanded(false);
    _setData(data.slice(0, 3));
  }, [data]);

  return (
    <div className={classes.wrap}>
      <ul className={classes.list}>
        {_data.map((item, i) => {
          return (
            <li key={i} className={classes.item}>
              <div
                onClick={() => toggleNotice(i)}
                className={clsx(classes.banner, selectedIdx === i && "open")}
              >
                {React.createElement(itemComponent, { item })}
              </div>
              <div
                className={clsx(classes.desc, selectedIdx === i && "open")}
                dangerouslySetInnerHTML={{
                  __html: item.desc,
                }}
              ></div>
            </li>
          );
        })}
      </ul>
      {isGuidePage &&
        (!isExpanded ? (
          <FlatButton text="더보기" onClick={showAllItems} />
        ) : (
          <FlatButton text="닫기" onClick={hideItems} />
        ))}
    </div>
  );
};

export default List;
